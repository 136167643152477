@import '../../index.scss';

.ra-price {
  display: block;

  span {
    color: #212121;
    @include fontBold;
    font-size: rem(35px);
    white-space: nowrap;
    font-weight: 500;
  }

  > small {
    @include fontRegular;
    font-size: rem(22px);
    font-weight: 500 !important;
  }

  &.ra-price-big {
    font-size: rem(40px);

    > small {
      font-size: rem(20px);
    }
  }
}
