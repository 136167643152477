// @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');
@import './variables';

// Ubuntu
@mixin fontLight {
  font-family: 'Ubuntu';
  font-weight: 300;
}

@mixin fontRegular {
  font-family: 'Ubuntu';
  font-weight: 400;
}

@mixin fontSemiBold {
  font-family: 'Ubuntu';
  font-weight: 500;
}

@mixin fontBold {
  font-family: 'Ubuntu';
  // font-weight: 700;
  font-weight: 500;
}

// Open sans font
@mixin fontOpenSansLight {
  font-family: 'Open Sans';
  font-weight: 300;
}

@mixin fontOpenSansRegular {
  font-family: 'Open Sans';
  font-weight: 400;
}

@mixin fontOpenSansSemiBold {
  font-family: 'Open Sans';
  font-weight: 600;
}

@mixin fontOpenSansBold {
  font-family: 'Open Sans';
  font-weight: 700;
}

// animations
@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// A mixin that converts from px to rem
@function rem-separator($list) {
  @if function-exists('list-separator') ==true {
    @return list-separator($list);
  }
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }
  @return if($test-list==$list, space, comma);
}

@mixin rem-baseline($zoom: 100%) {
  font-size: $zoom / 16px * $rem-baseline;
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to== 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to== 'rem' {
      $result: append($result, $value / ($rem-baseline / 1rem), $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }
  @return if(length($result) ==1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

//Font-size mixin to convert px to rem
@function calculateRem($size) {
  $remSize: $size / $base-font-size;
  @return #{$remSize}rem;
}

@mixin font-size($size) {
  font-size: calculateRem($size);
}
