@mixin skeleton {
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  animation: pulse 2s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

.skeleton,
.skeleton-auto {
  $from: #f5f5f5;
  $to: scale-color($from, $lightness: -10%);

  height: 100%;
  width: 100%;
  @include skeleton;
}

.skeleton-fluid {
  @include skeleton;
}

.skeleton-auto {
  height: auto !important;
}

.skeleton-text {
  color: transparent !important;
}

.skeleton-img {
  filter: blur(5px) grayscale(100%) !important;
}
