.ant-checkbox-wrapper {
  display: flex !important;

  > span {
    display: block;
    line-height: 1.5;
  }

  .ant-checkbox {
    width: 16px;
    height: 16px;
    top: 2px;
  }
}

.checkbox-small {
  .ant-checkbox {
    top: 0px;
  }

  > span {
    font-size: 11px;
  }
}
