@import '../../index.scss';

.ra-select-wrapper {
  border-radius: 12px;
  height: rem(50px);
  border: 1px solid #fff;
  background: #fff;
  position: relative;

  .ra-select {
    display: block;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: transparent;
    -moz-appearance: none;
    cursor: pointer;
    font-size: rem(16px);

    &:-ms-expand {
      display: none;
    }
  }

  .ra-select-arrow {
    position: absolute;
    width: rem(25px);
    height: rem(30px);
    right: rem(5px);
    top: rem(8px);
    transform: rotate(90deg);
    background: #fff;
    pointer-events: none;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #60ab3a;
      }
    }
  }
}
