@import '../../index';

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

.ra-checkbox-group {
  .ra-checkbox-empty {
    color: #a39791;
  }

  .ra-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    position: relative;

    &:not(:last-child) {
      margin-bottom: rem(6px);
    }

    &.ra-checkbox-disabled {
      cursor: not-allowed;

      .ra-checkbox-label span {
        color: #a39791;
      }

      .ra-checkbox-checkmark {
        background: #dadada;
      }
    }

    input {
      /* Hide the browser's default checkbox */
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .ra-checkbox-label {
      margin-left: rem(22.5px);
      display: block;

      .ra-checkbox-loading {
        background: linear-gradient(-90deg, #b6b6b6 0%, #ffffff 50%, #b6b6b6 100%) !important;
        background-size: 400% 400% !important;
        animation: ra-pulse 2s ease-in-out infinite;
        opacity: 0.7;
        border-radius: 2px;

        span {
          color: transparent !important;
        }
      }

      span {
        color: #ffffff;
        font-size: rem(17px);
      }
    }

    .ra-checkbox-checkmark {
      position: absolute;
      top: rem(5px);
      left: 0;
      height: rem(15px);
      width: rem(15px);
      background-color: #fff;
      border-radius: 3px;

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: rem(5px);
        top: rem(1px);
        width: rem(5px);
        height: rem(10px);
        border: solid #76c155;
        border-width: 0 1.5px 1.5px 0;
        transform: rotate(45deg);
      }
    }

    input {
      &:checked ~ .ra-checkbox-checkmark:after {
        display: block;
      }
    }
  }
}
