@import '../../../styles/helpers';

.private-layout-slider {
  overflow: auto;
  width: rem(120px) !important;
  min-width: rem(120px) !important;
  max-width: rem(120px) !important;
  height: 100vh;
  position: fixed;
  left: 0;

  .ant-menu-item-selected {
    color: #fff;
    background: #002140 !important;
  }

  .ant-menu-inline-collapsed {
    width: rem(120px);

    .ant-menu-item {
      padding: 0 !important;

      a {
        display: block;
        padding: 0 27.5px !important;

        &.active,
        &:hover {
          color: #fff;
          background: #002140;
        }
      }
    }
  }

  .ant-layout-sider-children {
    padding: rem(120px 0);
  }
}

.private-layout {
  margin-left: rem(120px);
}

.private-layout-header {
  background: #fff;
  padding: rem(0 15px);
  width: 100%;
  display: flex;

  h1 {
    width: 100%;
    margin: 0;
    line-height: inherit;
    display: block;
    @include fontBold;
    font-size: rem(28px);
  }

  .header-filters {
    width: auto;

    > *:not(:last-child) {
      margin-left: 10px;
    }
  }
}

.private-layout-content {
  padding: rem(50px);
  margin: 0;
  overflow: initial;
}
