@import '../../../styles/helpers';
@import '../../../styles/media-queries';

.layout {
  background: url('../../../resources/background-light.png') no-repeat #1e1e1e;
  background-position: top center;
  height: auto;
  min-height: 100vh;

  .layout-header {
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    padding: rem(40px 0);
    transition: 0.3s;
    z-index: 1;

    &.scroll-up {
      top: 0;
      background: rgba(0, 0, 0, 0.35);

      .layout-header-logo {
        height: rem(44px);

        @include mq($until: small-desktop) {
          height: rem(24px);
        }
      }
    }

    &.scroll-down {
      top: -100%;
    }

    .layout-header-logo {
      height: rem(71px);
      width: auto;
      transition: 0.3s;

      @include mq($until: small-desktop) {
        height: rem(24px);
      }
    }
  }
}

.layout-content {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: rem(1px);

  @include mq($until: wide) {
    width: calc(100% - 30px);
    margin-left: rem(15px);
    margin-right: rem(15px);
  }

  &.layout-content-vertical-fluid {
    height: 100%;
  }
}
