@import '../../index.scss';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ra-button {
  @include fontSemiBold;
  min-width: rem(210px);
  height: rem(57px);
  font-size: rem(20px);
  padding-left: rem(20px);
  padding-right: rem(20px);
  border-radius: 10px;
  cursor: pointer;
  border: none;

  &:disabled {
    background: #fafafa !important;
    cursor: not-allowed;

    &:hover {
      background: #ccc !important;
      border-color: #939292 !important;
    }
  }

  &:focus {
    outline: none !important;
  }

  &:hover {
    opacity: 0.9;
  }

  .ra-button-text {
    font-size: rem(20px);
    @include fontSemiBold;
  }

  > span {
    display: inline-block;
  }

  .anticon,
  svg,
  i,
  .ra-button-spin {
    left: -7.5px;
    position: relative;
  }

  // small version
  &.ra-button-size-small {
    height: rem(38px);
    padding-left: rem(15px);
    padding-right: rem(15px);
    min-width: rem(120px);
  }

  // large version
  &.ra-button-size-large {
    @include fontBold;
    font-size: rem(30px);
    height: rem(86px);
    padding-left: rem(50px);
    padding-right: rem(50px);
    min-width: rem(320px);

    @include mq($until: small-desktop) {
      padding-left: rem(40px);
      padding-right: rem(40px);
      font-size: rem(26px);
      height: rem(76px);
      min-width: rem(275px);
    }
  }

  &.ra-button-type-default {
    background-color: #fff;
    border-color: #d9d9d9;

    .ra-button-text {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  &.ra-button-type-primary {
    background-color: #53d624;
    border-color: #53d624;

    .ra-button-text {
      color: #fff;
    }
  }

  .ra-button-spin {
    display: inline-block;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(243, 243, 243, 0);
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: rem(17.5px);
    height: rem(17.5px);
    animation: spin 1s linear infinite;
  }
}
