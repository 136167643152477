@import '../../../../styles/helpers';
@import '../../../../styles/media-queries';

.checkout-finish-content {
  .checkout-finish-block {
    padding: rem(40px 25px 10px);

    @include mq($until: small-desktop) {
      padding: rem(50px 15px);
    }
  }

  h1 {
    @include fontRegular;
    font-size: rem(40px);
    margin-bottom: rem(15px);

    @include mq($until: small-desktop) {
      font-size: rem(36px);
    }
  }

  p {
    @include fontOpenSansRegular;
    font-size: rem(24px);
    margin: rem(0 auto);
    max-width: rem(770px);

    strong {
      @include fontOpenSansBold;
    }

    @include mq($until: small-desktop) {
      font-size: rem(21px);
    }
  }

  .checkout-finish-provider {
    margin-top: rem(75px);

    small {
      display: block;
      text-align: center;
      font-size: rem(18px);
      color: #303030;
    }

    img {
      display: block;
      margin: rem(0 auto);
      max-width: rem(150x);
      max-height: rem(150px);
    }
  }
}
