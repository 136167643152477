@import '../../../styles/media-queries';
@import '../../../styles/helpers';

.suspense {
  padding: 0.1px;
  height: 100vh;
  width: 100%;

  &:not(.suspense-type-private) {
    background: url('../../../resources/background-light.png') #1e1e1e no-repeat;
  }

  .suspense-block {
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.35);
    margin: rem(200px auto);
    box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08);
    filter: blur(2.5px);

    &.wide {
      width: 100%;
      max-width: 1140px;
      height: calc(100vh - 400px);
    }

    &.medium {
      width: 100%;
      max-width: 1025px;
      height: calc(100vh - 400px);
    }

    &.small {
      width: 100%;
      max-width: 660px;
      height: calc(100vh - 400px);
    }

    &.vertical-fluid {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-top: rem(100px);
      margin-bottom: 0;
      width: 100%;
      max-width: 1025px;
      min-height: calc(100vh - rem(100px));
    }
  }
}
