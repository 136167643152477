@import '../helpers';
@import '../media-queries';

.ant-form {
  &.vertical-form {
    .ant-form-item-label {
      padding: rem(10px 0px);
    }

    .time i {
      top: 10px;
    }
  }

  .form-item-button {
    .ant-form-item-children {
      display: flex;

      button {
        width: 100%;
        max-width: auto;
        min-width: auto;
      }

      > button,
      > a {
        display: block;
        width: 100%;

        &:not(:last-child) {
          margin-right: rem(17.5px);
          width: calc(100% + 17.5px);
        }
      }
    }
  }

  .ant-form-explain {
    margin-top: 0;
    font-size: rem(14px);
  }

  .ant-input-number,
  .ant-calendar-picker {
    width: 100%;
  }

  .has-error {
    .ant-select-auto-complete.ant-select .ant-select-selection {
      border-color: #f5222d;
    }
  }

  .ant-form-item {
    &.form-item-compact {
      margin-bottom: rem(2.5px);
    }

    button:not(:last-child),
    a {
      margin-right: rem(20px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-form-item-label {
    text-align: left;
    @include fontSemiBold;
    line-height: inherit;

    .anticon {
      margin-left: 2.5px;
      vertical-align: -2px;
      color: #ccc;
    }

    small {
      color: #f5222d;
      margin-left: 2.5px;
      vertical-align: 2px;
    }
  }

  .ant-select-auto-complete.ant-select {
    .ant-select-selection {
      border: 1px solid #d9d9d9;
    }

    .ant-select-selection__placeholder,
    .ant-input {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .ant-input {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}

.form-invert {
  .ant-form-item-label {
    @include fontRegular;
    line-height: inherit;

    > label {
      color: #fff;
    }
  }
}

.form-short {
  .ant-form-item:not(.form-item-fluid) {
    max-width: rem(220px);
    margin-bottom: rem(20px);

    @include mq($until: small-desktop) {
      max-width: 100%;
    }
  }
}

.form-default {
  $height: rem(37px);
  $label-font: rem(16px);
  $font: rem(16px);

  .ant-form-item-label {
    @include fontSemiBold;
    margin-bottom: rem(2.5px);

    @include mq($until: small-desktop) {
      margin-bottom: rem(1px);
    }

    > label {
      font-size: $label-font;

      @include mq($until: small-desktop) {
        font-size: 14px;
      }
    }
  }

  .ant-select {
    font-size: $font;

    .ant-select-selection--single {
      height: $height;

      .ant-select-selection__rendered {
        margin: rem(0 7.5px);
        line-height: $height - rem(2px);
      }
    }
  }
}

.form-big {
  $height: rem(70px);
  $label-font: rem(20px);
  $font: rem(18px);

  .ant-form-item-label {
    @include fontSemiBold;
    margin-bottom: rem(7.5px);

    > label {
      font-size: $label-font;
    }
  }

  .ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: $height;
  }

  .ant-select {
    font-size: $font;

    .ant-select-selection--single {
      height: $height;

      .ant-select-selection__rendered {
        margin: rem(0 22.5px);
        line-height: $height - rem(2px);
      }
    }
  }
}

.form-item-glue {
  .ant-form-item-children {
    display: flex;

    @include mq($until: small-desktop) {
      display: block;
    }

    .ant-select {
      &:first-child {
        .ant-select-selection {
          @include mq($from: small-desktop) {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0;
          }
        }
      }

      &:nth-child(2) {
        .ant-select-selection {
          @include mq($from: small-desktop) {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
        }
      }
    }

    .form-item-glue-short {
      width: rem(40%);

      @include mq($until: small-desktop) {
        width: 100%;
        margin-top: rem(5px);

        .ant-select-selection--single {
          height: 36px;

          .ant-select-selection__rendered {
            margin: rem(0 22.5px);
            line-height: rem(36px);
          }
        }
      }
    }
  }
}
