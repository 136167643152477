@import '../../../styles/helpers';

.informer-table {
  .ant-table-thead > tr:first-child > th:first-child {
    border-radius: 0;
  }

  .ant-table-thead > tr > th {
    vertical-align: top;
    padding: rem(12.5px 15px);
    background: #eaf0f6;
    color: rgba(0, 0, 0, 0.85);
    text-transform: uppercase;
    @include fontSemiBold;
  }

  .ant-table-tbody > tr {
    &:nth-child(odd) {
      background: #fff;
    }
    > td {
      vertical-align: top;
      padding: rem(10px 15px);
      @include fontRegular;

      a {
        color: #063467;
        @include fontSemiBold;

        &:hover {
          color: #084a96;
        }
      }
    }
  }

  .ant-table-pagination {
    margin-right: rem(25px);
  }
}
