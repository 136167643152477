@import '../../index.scss';

.ra-tag {
  position: relative;
  background-color: transparent;
  padding: rem(12.5px);
  max-width: 400px;
  border: 1px solid #53d624;
  padding-left: 1rem;
  padding-right: 2.22222rem;
  display: block;
  min-width: rem(253px);
  line-height: 1.4;
  border-radius: 12px;
  white-space: normal;
  text-overflow: ellipsis;

  span {
    color: #fff;
    font-size: rem(16px);
  }

  &:not(:last-child) {
    margin-bottom: rem(10px);
  }

  @include mq($until: small-desktop) {
    width: 100%;
    max-width: 100%;
    display: block;
    margin: rem(20px 0);
  }

  .ra-tag-action {
    display: block;
    @include fontOpenSansSemiBold;
    font-size: rem(16px);
    cursor: pointer;
    transition: 0.2s;
    color: #f5f5f5;
    font-weight: 700;
    margin-top: rem(5px);

    &:hover {
      color: #53d624;

      .ra-tag-action-arrow {
        margin-left: rem(7.5px);
      }
    }

    .ra-tag-action-arrow {
      vertical-align: rem(1px);
      font-size: rem(14px);
      margin-left: rem(5px);
      color: #f5f5f5;
    }
  }

  > span {
    overflow: hidden;
    display: block;
  }

  > img,
  > svg,
  > i {
    position: absolute;
    left: rem(11px);
    top: 50%;
    transform: translateY(-50%);
    color: #595959;

    path {
      fill: #595959;
    }
  }

  svg {
    left: auto;
    right: 0.85111rem;

    path {
      fill: #fff;
    }
  }
}
