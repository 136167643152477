@import '../../index.scss';

@keyframes ra-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ra-animate-bottom {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.ra-loader-container {
  .ra-loader-spin-container {
    position: relative;
    width: 100%;

    .ra-loader-spin {
      position: absolute;
      top: rem(35px);
      left: calc(50% - 30px);
      transform: translateY(-50%);
      border: 6px solid rgba(243, 243, 243, 0.25);
      border-top: 6px solid #53d624;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      animation: ra-spin 1s linear infinite;
    }

    .ra-loader-spin-label {
      display: block;
      width: 100%;
      position: absolute;
      top: rem(130px);
      transform: translateY(-50%);
      color: #fff;
      font-size: rem(16px);
      text-align: center;
    }
  }

  .ra-loader-workers-container {
    min-height: rem(800px);
    height: 100%;
    padding-top: rem(200px);
    width: auto;

    &.disconnected .ra-loader-worker img {
      filter: grayscale(100%);
    }

    .ra-loader-worker {
      position: relative;
      width: 100%;
      max-width: rem(350px);
      margin: 0 auto;
      padding: rem(15px);
      background: #fff;
      border-radius: 7px;
      animation: ra-animate-bottom 2s;

      &:not(:last-child) {
        margin-bottom: rem(15px);
      }

      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: rem(100px);
      }

      > span {
        margin-top: rem(15px);
        @include fontOpenSansSemiBold;
        text-align: center;
        display: block;
        font-size: rem(18px);
        color: #000;
      }
    }
  }
}
