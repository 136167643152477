@charset "UTF-8"; // Fixes an issue where Ruby locale is not set properly

$mq-base-font-size: 16px !default;

$mq-responsive: true !default;

$mq-breakpoints: (
  mobile: 320px,
  small-tablet: 480px,
  tablet: 640px,
  small-desktop: 768px,
  desktop: 1024px,
  wide: 1280px,
  extra-wide: 1366px,
  extra-extra-wide: 1400px,
) !default;

$mq-static-breakpoint: desktop !default;
$mq-show-breakpoints: (mobile, small-tablet, tablet, small-desktop, desktop, wide, extra-wide) !default;
$mq-media-type: all !default;

@function mq-px2em($px, $base-font-size: $mq-base-font-size) {
  @if unitless($px) {
    @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
    @return mq-px2em($px * 1px, $base-font-size);
  } @else if unit($px) ==em {
    @return $px;
  }
  @return ($px / $base-font-size) * 1em;
}

@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  } @else {
    @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
  }
}

@mixin mq(
  $from: false,
  $until: false,
  $and: false,
  $media-type: $mq-media-type,
  $breakpoints: $mq-breakpoints,
  $responsive: $mq-responsive,
  $static-breakpoint: $mq-static-breakpoint
) {
  $min-width: 0;
  $max-width: 0;
  $media-query: ''; // From: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) ==number {
      $min-width: mq-px2em($from);
    } @else {
      $min-width: mq-px2em(mq-get-breakpoint-width($from, $breakpoints));
    }
  } // Until: that breakpoint (exclusive)
  @if $until {
    @if type-of($until) ==number {
      $max-width: mq-px2em($until);
    } @else {
      $max-width: mq-px2em(mq-get-breakpoint-width($until, $breakpoints)) - 0.01em;
    }
  } // Responsive support is disabled, rasterize the output outside @media blocks
  // The browser will rely on the cascade itself.
  @if $responsive==false {
    $static-breakpoint-width: mq-get-breakpoint-width($static-breakpoint, $breakpoints);
    $target-width: mq-px2em($static-breakpoint-width); // Output only rules that start at or span our target width
    @if ($and==false and $min-width <=$target-width and ($until==false or $max-width >=$target-width)) {
      @content;
    }
  }
  // Responsive support is enabled, output rules inside @media queries
  @else {
    @if $min-width !=0 {
      $media-query: '#{$media-query} and (min-width: #{$min-width})';
    }
    @if $max-width !=0 {
      $media-query: '#{$media-query} and (max-width: #{$max-width})';
    }
    @if $and {
      $media-query: '#{$media-query} and #{$and}';
    } // Remove unnecessary media query prefix 'all and '
    @if ($media-type== 'all' and $media-query != '') {
      $media-type: '';
      $media-query: str-slice(unquote($media-query), 6);
    }
    @media #{$media-type + $media-query} {
      @content;
    }
  }
}

@mixin mq-add-breakpoint($name, $width) {
  $new-breakpoint: (
    $name: $width,
  );
  $mq-breakpoints: map-merge($mq-breakpoints, $new-breakpoint) !global;
}

@mixin mq-show-breakpoints($show-breakpoints: $mq-show-breakpoints, $breakpoints: $mq-breakpoints) {
  body:before {
    background-color: #fcf8e3;
    border-bottom: 1px solid #fbeed5;
    border-left: 1px solid #fbeed5;
    color: #c09853;
    font: small-caption;
    padding: 3px 6px;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; // Loop through the breakpoints that should be shown

    @each $show-breakpoint in $show-breakpoints {
      $width: mq-get-breakpoint-width($show-breakpoint, $breakpoints);
      @include mq($show-breakpoint, $breakpoints: $breakpoints) {
        content: '#{$show-breakpoint} ≥ #{$width} (#{mq-px2em($width)})';
      }
    }
  }
}
