@import '../../../../../styles/helpers';

.logger-container {
  padding-top: rem(30px);

  pre {
    background: #fff;
    padding: rem(20px);
  }

  .logger-toggler {
    text-transform: uppercase;
    text-align: center;
    margin-top: rem(-15px);
    border-top: 1px solid #ccc;
    background: #fff;
    padding: rem(10px 20px);
    @include fontSemiBold;
  }
}
