@import '../../../../styles/helpers';
@import '../../../../styles/media-queries';

@keyframes width-animation {
  from {
    width: rem(400px);
  }
  to {
    width: 100%;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.checkout-content {
  margin-top: rem(25px);

  .button {
    margin-top: rem(5px);

    @include mq($until: small-desktop) {
      margin-top: rem(-25px);
      width: 100%;
      display: block;
    }
  }

  img {
    max-height: rem(75px);
  }

  .checkout-price {
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
  }

  .ant-row-flex {
    @include mq($until: small-desktop) {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .ant-col {
      @include mq($until: small-desktop) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        float: inherit;

        &:not(:first-child):not(.ant-form-item-control-wrapper) {
          margin-top: rem(40px);
        }
      }
    }
  }

  .checkout-privacy {
    margin: rem(25px 0);

    > span {
      position: relative;
      display: block;
      padding-left: rem(32.5px);
      font-size: rem(14px);

      &:not(:last-child) {
        margin-bottom: rem(15px);
      }

      i {
        font-size: rem(24px);
        position: absolute;
        width: rem(21px);
        height: rem(21px);
        top: -2.5px;
        left: 0;
        color: #25aae1;
      }
    }
  }
}

.checkout-read-only {
  color: #595959;
}

.zh-card-header {
  &.zh-card-header-show-address {
    .zh-card-header-content {
      opacity: 0;
      animation: fade-out 0.25s;
    }

    .zh-card-header-toolbar {
      right: 0;
      position: absolute;
      padding: 0;
      animation: width-animation 0.25s;
      width: 100%;
    }

    .ra-autocomplete .ra-autocomplete-suggestions {
      top: rem(62.5px);

      &.alternative {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        top: rem(97.5px);
      }
    }
  }

  .zh-card-header-content {
    padding: rem(7.5px 0);

    .header-go-back {
      display: inline-block;
      margin-bottom: rem(30px);
      color: #fff;
      font-size: rem(24px);
      padding-left: rem(20px);
      cursor: pointer;
      transition: 0.1s;

      &:hover {
        opacity: 0.9;
      }

      .go-back-arrow {
        margin-left: 0 !important;
        position: absolute;
        left: rem(2.5px);
        top: rem(11px);
        transform: rotate(180deg);
        display: inline-block;
      }
    }

    h3.title {
      margin-bottom: 0;
      font-size: rem(62px);
    }
  }
}
