@import '../../index.scss';

.ra-description-list {
  padding: 0;
  margin: 0;

  .ra-description-list-item {
    display: flex;
    list-style: none;
    padding: rem(10px 0);
    border-top: 1px dashed #dadada;

    &.active {
      background: #fffbd8;
    }

    > span {
      font-size: rem(16px);
      display: block;

      &:first-child {
        @include fontOpenSansRegular;
        width: 100%;
      }

      &:last-child {
        @include fontOpenSansSemiBold;
        width: auto;
        white-space: nowrap;
      }
    }
  }
}

.ra-description-list-header {
  margin-bottom: rem(15px);
  font-size: rem(20px) !important;
  @include fontBold;
  font-weight: 500 !important;
  color: #595959 !important;
  text-shadow: none !important;
  display: flex;

  > span {
    &:first-child {
      width: 100%;
    }

    &:not(:first-child) {
      width: auto;
    }
  }

  .ra-price {
    margin-top: rem(-15px);
  }
}
