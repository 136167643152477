@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.card {
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.35);
  padding: 3.33333rem 5rem;
  margin: 11.11111rem auto;
  box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08); }
  @media (max-width: 47.99em) {
    .card {
      padding: 0.55556rem; } }
  .card > h1,
  .card > h2,
  .card > h3,
  .card > .zh-card-header h1,
  .card > .zh-card-header h2,
  .card > .zh-card-header h3,
  .card .title {
    color: #fff;
    font-size: 3.55556rem;
    font-family: 'Ubuntu';
    font-weight: 500;
    margin: 0 0 0.41667rem; }
    @media (max-width: 47.99em) {
      .card > h1,
      .card > h2,
      .card > h3,
      .card > .zh-card-header h1,
      .card > .zh-card-header h2,
      .card > .zh-card-header h3,
      .card .title {
        font-size: 2.33333rem; } }
  .card > h3,
  .card h3.title {
    font-size: 2rem; }
    @media (max-width: 47.99em) {
      .card > h3,
      .card h3.title {
        font-size: 1.77778rem; } }
  .card p {
    color: #fff;
    margin: 0 0 2.77778rem;
    font-size: 1.11111rem;
    font-family: 'Ubuntu';
    font-weight: 400; }
    .card p.small {
      font-size: 0.77778rem;
      margin: 0 0 1.38889rem; }
    .card p.no-margin {
      margin: 0; }
    .card p b,
    .card p strong {
      color: #fff; }
    @media (max-width: 47.99em) {
      .card p {
        font-size: 0.88889rem;
        margin-bottom: 1.38889rem; }
        .card p.small {
          margin-bottom: 1.11111rem; } }
  .card.card-wide {
    width: 100%;
    max-width: 1140px; }
  .card.card-medium {
    margin: 5.55556rem auto;
    width: 100%;
    max-width: 1025px; }
    @media (max-width: 47.99em) {
      .card.card-medium {
        margin: 4.16667rem auto; } }
  .card.card-small {
    padding: 4.72222rem;
    margin: 9.16667rem auto;
    width: 100%;
    max-width: 660px; }
    @media (max-width: 47.99em) {
      .card.card-small {
        margin: 4.16667rem auto;
        padding: 2.77778rem 1.66667rem; } }
  .card.results-app-step-2 {
    padding: 3.33333rem 5rem; }
    @media (max-width: 47.99em) {
      .card.results-app-step-2 {
        padding: 1.66667rem; } }
  .card.vertical-fluid, .card.results-app-step-2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 9.16667rem;
    margin-bottom: 0;
    width: 100%;
    max-width: 1025px;
    min-height: calc(100vh - 55px) !important; }
    @media (max-width: 47.99em) {
      .card.vertical-fluid, .card.results-app-step-2 {
        margin-top: 3.88889rem;
        min-height: calc(100vh - rem(70px)) !important; } }
  .card .card-content {
    padding: 4.16667rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08); }
    @media (max-width: 47.99em) {
      .card .card-content {
        padding: 1.38889rem; } }
    .card .card-content.no-padding {
      padding: 0; }
    .card .card-content:not(:last-child) {
      margin-bottom: 1.94444rem; }
    .card .card-content p,
    .card .card-content strong {
      color: #303030; }
    .card .card-content .provider-logo {
      margin-top: 1.38889rem;
      max-width: 13.88889rem;
      max-height: 6.38889rem;
      float: right; }
      @media (max-width: 47.99em) {
        .card .card-content .provider-logo {
          float: none;
          width: 70%;
          height: 100%;
          margin: auto;
          max-height: 100%;
          margin-top: -2.22222rem; } }
    .card .card-content .order-form label {
      padding: 2px 0;
      display: block;
      font-size: 16px; }
    .card .card-content .order-form .ant-input {
      height: 48px;
      margin-top: 2px;
      border-radius: 8px; }
    @media (max-width: 47.99em) {
      .card .card-content .order-form .ant-form-item-label {
        text-align: left; } }
    .card .card-content .order-form .ant-input-group-compact input:first-child {
      border-radius: 8px 0 0 8px; }
    .card .card-content .order-form .ant-input-group-compact input:last-child {
      border-radius: 0 8px 8px 0; }
    .card .card-content .order-form .ant-calendar-picker {
      width: 100%; }
      .card .card-content .order-form .ant-calendar-picker input {
        padding-left: 45px; }
      .card .card-content .order-form .ant-calendar-picker .ant-calendar-picker-icon {
        right: auto;
        left: 15px;
        font-size: 18px; }
    @media (max-width: 47.99em) {
      .card .card-content .order-form .ant-col {
        margin-top: 0; } }
    .card .card-content .order-form .ant-col .ant-col-12:first-child {
      padding-right: 12px; }
      @media (max-width: 47.99em) {
        .card .card-content .order-form .ant-col .ant-col-12:first-child {
          padding-right: 0; } }
    .card .card-content .order-form .ant-col .ant-col-12:last-child {
      padding-left: 12px; }
      @media (max-width: 47.99em) {
        .card .card-content .order-form .ant-col .ant-col-12:last-child {
          padding-left: 0; } }
    .card .card-content .order-form .checkbox-small > span {
      font-size: 14px;
      line-height: 1.2; }
    .card .card-content .order-form .ant-form-item {
      margin-bottom: 16px; }
    .card .card-content table {
      font-size: 1.61111rem;
      margin-top: 0.72222rem;
      width: 100%;
      line-height: 2.33333rem; }
      .card .card-content table tr td .label {
        font-weight: 600;
        margin-bottom: 0.83333rem; }
      .card .card-content table tr td div:not(:last-child) {
        margin-bottom: 0.55556rem; }
      .card .card-content table tr td .anticon {
        margin-left: 0.55556rem; }
        .card .card-content table tr td .anticon svg {
          color: #dedede; }
      .card .card-content table tr.total-tr {
        font-weight: 700; }
        .card .card-content table tr.total-tr td {
          padding-top: 1.52778rem;
          font-size: 1.80556rem;
          font-weight: 700; }
      .card .card-content table.details {
        margin-bottom: -20px; }
        .card .card-content table.details td {
          vertical-align: top;
          padding-bottom: 10px;
          padding-bottom: 25px;
          line-height: 1.6rem; }
        .card .card-content table.details td:first-child {
          width: 43%; }
  .card .zh-card-header {
    position: relative;
    display: flex; }
    @media (max-width: 47.99em) {
      .card .zh-card-header {
        display: block; } }
    .card .zh-card-header .zh-card-header-content {
      width: 100%; }
    .card .zh-card-header .zh-card-header-toolbar {
      padding: 0.41667rem 0rem 0.41667rem 1.38889rem;
      width: auto; }
      @media (max-width: 47.99em) {
        .card .zh-card-header .zh-card-header-toolbar {
          padding: 0; } }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.button {
  font-family: 'Ubuntu';
  font-weight: 500;
  min-width: 18.61111rem;
  height: 5.27778rem;
  font-size: 1.66667rem;
  padding-left: 1.66667rem;
  padding-right: 1.66667rem;
  border-radius: 10px;
  border: none;
  cursor: pointer; }
  .button:disabled {
    background: #fafafa !important;
    cursor: not-allowed; }
    .button:disabled:hover {
      background: #ccc !important;
      border-color: #939292 !important; }
  .button:focus {
    outline: none !important; }
  .button:hover {
    opacity: 0.9; }
  .button > span {
    display: inline-block; }
  .button .anticon,
  .button svg,
  .button i,
  .button .button-spin {
    left: -7.5px;
    position: relative; }
  .button.button-size-small {
    height: 2.11111rem;
    padding-left: 0.83333rem;
    padding-right: 0.83333rem;
    min-width: 6.66667rem; }
  .button.button-size-large {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 1.66667rem;
    height: 4.77778rem;
    padding-left: 2.77778rem;
    padding-right: 2.77778rem;
    min-width: 17.77778rem; }
    @media (max-width: 47.99em) {
      .button.button-size-large {
        padding-left: 2.22222rem;
        padding-right: 2.22222rem;
        font-size: 1.44444rem;
        height: 4.22222rem;
        min-width: 15.27778rem; } }
  .button.button-type-default {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9; }
  .button.button-type-primary {
    color: #fff;
    background-color: #53d624;
    border-color: #53d624; }
    .button.button-type-primary:disabled {
      background: #90e576 !important;
      border-color: #90e576 !important;
      cursor: not-allowed; }
  .button .button-spin {
    display: inline-block;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(243, 243, 243, 0);
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: 0.97222rem;
    height: 0.97222rem;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ra-loader-container {
  position: relative; }
  .ra-loader-container .loader-spin {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    transform: translate(-50%, -50%);
    border: 6px solid rgba(243, 243, 243, 0.25);
    border-top: 6px solid #53d624;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite; }

.time i {
  top: 1px;
  color: #a2bacd; }

.time.no-wrap {
  white-space: nowrap; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.description-list {
  padding: 0;
  margin: 0; }
  .description-list .description-list-item {
    display: flex;
    list-style: none;
    padding: 0.55556rem 0;
    border-top: 1px dashed #dadada; }
    .description-list .description-list-item.active {
      background: #fffbd8; }
    .description-list .description-list-item > span {
      font-size: 0.88889rem;
      display: block; }
      .description-list .description-list-item > span:first-child {
        font-family: 'Ubuntu';
        font-weight: 500;
        width: 100%; }
      .description-list .description-list-item > span:last-child {
        font-family: 'Ubuntu';
        font-weight: 500;
        width: auto;
        white-space: nowrap; }

.description-list-header {
  margin-bottom: 0.83333rem;
  font-size: 1.11111rem;
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #595959;
  display: flex; }
  .description-list-header.big {
    font-size: 2rem; }
  @media (max-width: 47.99em) {
    .description-list-header {
      margin-top: 1.94444rem; } }
  .description-list-header > span:first-child {
    width: 100%; }
  .description-list-header > span:not(:first-child) {
    width: auto; }
  .description-list-header .price {
    margin-top: -0.83333rem; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.layout {
  background: url(/static/media/background-light.06df30ef.png) no-repeat #1e1e1e;
  background-position: top center;
  height: auto;
  min-height: 100vh; }
  .layout .layout-header {
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    padding: 2.22222rem 0;
    transition: 0.3s;
    z-index: 1; }
    .layout .layout-header.scroll-up {
      top: 0;
      background: rgba(0, 0, 0, 0.35); }
      .layout .layout-header.scroll-up .layout-header-logo {
        height: 2.44444rem; }
        @media (max-width: 47.99em) {
          .layout .layout-header.scroll-up .layout-header-logo {
            height: 1.33333rem; } }
    .layout .layout-header.scroll-down {
      top: -100%; }
    .layout .layout-header .layout-header-logo {
      height: 3.94444rem;
      width: auto;
      transition: 0.3s; }
      @media (max-width: 47.99em) {
        .layout .layout-header .layout-header-logo {
          height: 1.33333rem; } }

.layout-content {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.05556rem; }
  @media (max-width: 79.99em) {
    .layout-content {
      width: calc(100% - 30px);
      margin-left: 0.83333rem;
      margin-right: 0.83333rem; } }
  .layout-content.layout-content-vertical-fluid {
    height: 100%; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.private-layout-slider {
  overflow: auto;
  width: 6.66667rem !important;
  min-width: 6.66667rem !important;
  max-width: 6.66667rem !important;
  height: 100vh;
  position: fixed;
  left: 0; }
  .private-layout-slider .ant-menu-item-selected {
    color: #fff;
    background: #002140 !important; }
  .private-layout-slider .ant-menu-inline-collapsed {
    width: 6.66667rem; }
    .private-layout-slider .ant-menu-inline-collapsed .ant-menu-item {
      padding: 0 !important; }
      .private-layout-slider .ant-menu-inline-collapsed .ant-menu-item a {
        display: block;
        padding: 0 27.5px !important; }
        .private-layout-slider .ant-menu-inline-collapsed .ant-menu-item a.active, .private-layout-slider .ant-menu-inline-collapsed .ant-menu-item a:hover {
          color: #fff;
          background: #002140; }
  .private-layout-slider .ant-layout-sider-children {
    padding: 6.66667rem 0; }

.private-layout {
  margin-left: 6.66667rem; }

.private-layout-header {
  background: #fff;
  padding: 0 0.83333rem;
  width: 100%;
  display: flex; }
  .private-layout-header h1 {
    width: 100%;
    margin: 0;
    line-height: inherit;
    display: block;
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 1.55556rem; }
  .private-layout-header .header-filters {
    width: auto; }
    .private-layout-header .header-filters > *:not(:last-child) {
      margin-left: 10px; }

.private-layout-content {
  padding: 2.77778rem;
  margin: 0;
  overflow: visible;
  overflow: initial; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.suspense {
  padding: 0.1px;
  height: 100vh;
  width: 100%; }
  .suspense:not(.suspense-type-private) {
    background: url(/static/media/background-light.06df30ef.png) #1e1e1e no-repeat; }
  .suspense .suspense-block {
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.35);
    margin: 11.11111rem auto;
    box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08);
    -webkit-filter: blur(2.5px);
            filter: blur(2.5px); }
    .suspense .suspense-block.wide {
      width: 100%;
      max-width: 1140px;
      height: calc(100vh - 400px); }
    .suspense .suspense-block.medium {
      width: 100%;
      max-width: 1025px;
      height: calc(100vh - 400px); }
    .suspense .suspense-block.small {
      width: 100%;
      max-width: 660px;
      height: calc(100vh - 400px); }
    .suspense .suspense-block.vertical-fluid {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-top: 5.55556rem;
      margin-bottom: 0;
      width: 100%;
      max-width: 1025px;
      min-height: calc(100vh - rem(100px)); }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.checkout-finish-content .checkout-finish-block {
  padding: 2.22222rem 1.38889rem 0.55556rem; }
  @media (max-width: 47.99em) {
    .checkout-finish-content .checkout-finish-block {
      padding: 2.77778rem 0.83333rem; } }

.checkout-finish-content h1 {
  font-family: 'Ubuntu';
  font-weight: 400;
  font-size: 2.22222rem;
  margin-bottom: 0.83333rem; }
  @media (max-width: 47.99em) {
    .checkout-finish-content h1 {
      font-size: 2rem; } }

.checkout-finish-content p {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 1.33333rem;
  margin: 0 auto;
  max-width: 42.77778rem; }
  .checkout-finish-content p strong {
    font-family: 'Open Sans';
    font-weight: 700; }
  @media (max-width: 47.99em) {
    .checkout-finish-content p {
      font-size: 1.16667rem; } }

.checkout-finish-content .checkout-finish-provider {
  margin-top: 4.16667rem; }
  .checkout-finish-content .checkout-finish-provider small {
    display: block;
    text-align: center;
    font-size: 1rem;
    color: #303030; }
  .checkout-finish-content .checkout-finish-provider img {
    display: block;
    margin: 0 auto;
    max-width: 150x;
    max-height: 8.33333rem; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ra-button {
  font-family: 'Ubuntu';
  font-weight: 500;
  min-width: 18.66667rem;
  height: 5.06667rem;
  font-size: 1.77778rem;
  padding-left: 1.77778rem;
  padding-right: 1.77778rem;
  border-radius: 10px;
  cursor: pointer;
  border: none; }
  .ra-button:disabled {
    background: #fafafa !important;
    cursor: not-allowed; }
    .ra-button:disabled:hover {
      background: #ccc !important;
      border-color: #939292 !important; }
  .ra-button:focus {
    outline: none !important; }
  .ra-button:hover {
    opacity: 0.9; }
  .ra-button .ra-button-text {
    font-size: 1.77778rem;
    font-family: 'Ubuntu';
    font-weight: 500; }
  .ra-button > span {
    display: inline-block; }
  .ra-button .anticon,
  .ra-button svg,
  .ra-button i,
  .ra-button .ra-button-spin {
    left: -7.5px;
    position: relative; }
  .ra-button.ra-button-size-small {
    height: 3.37778rem;
    padding-left: 1.33333rem;
    padding-right: 1.33333rem;
    min-width: 10.66667rem; }
  .ra-button.ra-button-size-large {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 2.66667rem;
    height: 7.64444rem;
    padding-left: 4.44444rem;
    padding-right: 4.44444rem;
    min-width: 28.44444rem; }
    @media (max-width: 68.25667em) {
      .ra-button.ra-button-size-large {
        padding-left: 3.55556rem;
        padding-right: 3.55556rem;
        font-size: 2.31111rem;
        height: 6.75556rem;
        min-width: 24.44444rem; } }
  .ra-button.ra-button-type-default {
    background-color: #fff;
    border-color: #d9d9d9; }
    .ra-button.ra-button-type-default .ra-button-text {
      color: rgba(0, 0, 0, 0.65); }
  .ra-button.ra-button-type-primary {
    background-color: #53d624;
    border-color: #53d624; }
    .ra-button.ra-button-type-primary .ra-button-text {
      color: #fff; }
  .ra-button .ra-button-spin {
    display: inline-block;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(243, 243, 243, 0);
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: 1.55556rem;
    height: 1.55556rem;
    -webkit-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-tag {
  position: relative;
  background-color: transparent;
  padding: 1.11111rem;
  max-width: 400px;
  border: 1px solid #53d624;
  padding-left: 1rem;
  padding-right: 2.22222rem;
  display: block;
  min-width: 22.48889rem;
  line-height: 1.4;
  border-radius: 12px;
  white-space: normal;
  text-overflow: ellipsis; }
  .ra-tag span {
    color: #fff;
    font-size: 1.42222rem; }
  .ra-tag:not(:last-child) {
    margin-bottom: 0.88889rem; }
  @media (max-width: 68.25667em) {
    .ra-tag {
      width: 100%;
      max-width: 100%;
      display: block;
      margin: 1.77778rem 0; } }
  .ra-tag .ra-tag-action {
    display: block;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.42222rem;
    cursor: pointer;
    transition: 0.2s;
    color: #f5f5f5;
    font-weight: 700;
    margin-top: 0.44444rem; }
    .ra-tag .ra-tag-action:hover {
      color: #53d624; }
      .ra-tag .ra-tag-action:hover .ra-tag-action-arrow {
        margin-left: 0.66667rem; }
    .ra-tag .ra-tag-action .ra-tag-action-arrow {
      vertical-align: 0.08889rem;
      font-size: 1.24444rem;
      margin-left: 0.44444rem;
      color: #f5f5f5; }
  .ra-tag > span {
    overflow: hidden;
    display: block; }
  .ra-tag > img,
  .ra-tag > svg,
  .ra-tag > i {
    position: absolute;
    left: 0.97778rem;
    top: 50%;
    transform: translateY(-50%);
    color: #595959; }
    .ra-tag > img path,
    .ra-tag > svg path,
    .ra-tag > i path {
      fill: #595959; }
  .ra-tag svg {
    left: auto;
    right: 0.85111rem; }
    .ra-tag svg path {
      fill: #fff; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-price {
  display: block; }
  .ra-price span {
    color: #212121;
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 3.11111rem;
    white-space: nowrap;
    font-weight: 500; }
  .ra-price > small {
    font-family: 'Ubuntu';
    font-weight: 400;
    font-size: 1.95556rem;
    font-weight: 500 !important; }
  .ra-price.ra-price-big {
    font-size: 3.55556rem; }
    .ra-price.ra-price-big > small {
      font-size: 1.77778rem; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

@-webkit-keyframes ra-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes ra-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes ra-animate-bottom {
  from {
    bottom: -50px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }

@keyframes ra-animate-bottom {
  from {
    bottom: -50px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }

.ra-loader-container .ra-loader-spin-container {
  position: relative;
  width: 100%; }
  .ra-loader-container .ra-loader-spin-container .ra-loader-spin {
    position: absolute;
    top: 3.11111rem;
    left: calc(50% - 30px);
    transform: translateY(-50%);
    border: 6px solid rgba(243, 243, 243, 0.25);
    border-top: 6px solid #53d624;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: ra-spin 1s linear infinite;
            animation: ra-spin 1s linear infinite; }
  .ra-loader-container .ra-loader-spin-container .ra-loader-spin-label {
    display: block;
    width: 100%;
    position: absolute;
    top: 11.55556rem;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1.42222rem;
    text-align: center; }

.ra-loader-container .ra-loader-workers-container {
  min-height: 71.11111rem;
  height: 100%;
  padding-top: 17.77778rem;
  width: auto; }
  .ra-loader-container .ra-loader-workers-container.disconnected .ra-loader-worker img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%); }
  .ra-loader-container .ra-loader-workers-container .ra-loader-worker {
    position: relative;
    width: 100%;
    max-width: 31.11111rem;
    margin: 0 auto;
    padding: 1.33333rem;
    background: #fff;
    border-radius: 7px;
    -webkit-animation: ra-animate-bottom 2s;
            animation: ra-animate-bottom 2s; }
    .ra-loader-container .ra-loader-workers-container .ra-loader-worker:not(:last-child) {
      margin-bottom: 1.33333rem; }
    .ra-loader-container .ra-loader-workers-container .ra-loader-worker img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 8.88889rem; }
    .ra-loader-container .ra-loader-workers-container .ra-loader-worker > span {
      margin-top: 1.33333rem;
      font-family: 'Open Sans';
      font-weight: 600;
      text-align: center;
      display: block;
      font-size: 1.6rem;
      color: #000; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-description-list {
  padding: 0;
  margin: 0; }
  .ra-description-list .ra-description-list-item {
    display: flex;
    list-style: none;
    padding: 0.88889rem 0;
    border-top: 1px dashed #dadada; }
    .ra-description-list .ra-description-list-item.active {
      background: #fffbd8; }
    .ra-description-list .ra-description-list-item > span {
      font-size: 1.42222rem;
      display: block; }
      .ra-description-list .ra-description-list-item > span:first-child {
        font-family: 'Open Sans';
        font-weight: 400;
        width: 100%; }
      .ra-description-list .ra-description-list-item > span:last-child {
        font-family: 'Open Sans';
        font-weight: 600;
        width: auto;
        white-space: nowrap; }

.ra-description-list-header {
  margin-bottom: 1.33333rem;
  font-size: 1.77778rem !important;
  font-family: 'Ubuntu';
  font-weight: 500;
  font-weight: 500 !important;
  color: #595959 !important;
  text-shadow: none !important;
  display: flex; }
  .ra-description-list-header > span:first-child {
    width: 100%; }
  .ra-description-list-header > span:not(:first-child) {
    width: auto; }
  .ra-description-list-header .ra-price {
    margin-top: -1.33333rem; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

@-webkit-keyframes ra-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes ra-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ra-loader-count-spin {
  display: inline-block;
  border: 1px solid rgba(243, 243, 243, 0.25);
  border-top: 1px solid #ffffff;
  border-radius: 100%;
  width: 1.24444rem;
  height: 1.24444rem;
  -webkit-animation: ra-spin 1s linear infinite;
          animation: ra-spin 1s linear infinite;
  vertical-align: -2px; }
  .ra-loader-count-spin.ra-spin-size-big {
    width: 1.77778rem;
    height: 1.77778rem;
    border: 2px solid rgba(243, 243, 243, 0.25);
    border-top: 2px solid #ffffff;
    vertical-align: -3px; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-select-wrapper {
  border-radius: 12px;
  height: 4.44444rem;
  border: 1px solid #fff;
  background: #fff;
  position: relative; }
  .ra-select-wrapper .ra-select {
    display: block;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: transparent;
    -moz-appearance: none;
    cursor: pointer;
    font-size: 1.42222rem; }
    .ra-select-wrapper .ra-select:-ms-expand {
      display: none; }
  .ra-select-wrapper .ra-select-arrow {
    position: absolute;
    width: 2.22222rem;
    height: 2.66667rem;
    right: 0.44444rem;
    top: 0.71111rem;
    transform: rotate(90deg);
    background: #fff;
    pointer-events: none; }
    .ra-select-wrapper .ra-select-arrow svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .ra-select-wrapper .ra-select-arrow svg path {
        fill: #60ab3a; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-skybar-container {
  position: relative;
  height: 0.71111rem;
  width: 100%; }
  .ra-skybar-container .ra-skybar-bar {
    height: 100%;
    width: 100%;
    background: #d9d7e2;
    border-radius: 4px; }
  .ra-skybar-container .ra-skybar-pattern {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    min-width: 0.88889rem;
    height: 100%;
    background: #61ab3a;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: width 0.5s; }
    .ra-skybar-container .ra-skybar-pattern.filled {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-provider-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08);
  display: flex; }
  @media (max-width: 68.25667em) {
    .ra-provider-item {
      display: block; } }
  .ra-provider-item:not(:last-child) {
    margin-bottom: 1.77778rem; }
  .ra-provider-item .ra-provider-item-img {
    padding: 1.33333rem 2.22222rem;
    width: 30%;
    flex-wrap: wrap; }
    @media (max-width: 68.25667em) {
      .ra-provider-item .ra-provider-item-img {
        padding: 2.22222rem;
        border-bottom: 1px solid #dadada;
        display: block;
        width: 100%; } }
    @media (max-width: 56.87889em) {
      .ra-provider-item .ra-provider-item-img {
        padding: 1.33333rem 2.22222rem 0; } }
    .ra-provider-item .ra-provider-item-img .ra-provider-item-img-container {
      width: 100%;
      height: 175px;
      display: flex;
      text-align: center; }
      .ra-provider-item .ra-provider-item-img .ra-provider-item-img-container img {
        object-fit: contain;
        max-width: 100%;
        height: auto;
        margin: 0 auto; }
    .ra-provider-item .ra-provider-item-img .ra-provider-description-show-more {
      display: none;
      width: 100%;
      text-align: center;
      margin-top: 1.33333rem; }
      @media (max-width: 56.87889em) {
        .ra-provider-item .ra-provider-item-img .ra-provider-description-show-more {
          display: block !important; } }
    .ra-provider-item .ra-provider-item-img > img {
      -webkit-filter: blur(0);
              filter: blur(0);
      display: block;
      transition: 0.3s;
      margin: auto; }
    .ra-provider-item .ra-provider-item-img .ra-provider-expanded-list {
      margin-top: 1.11111rem; }
  .ra-provider-item .ra-provider-item-content {
    width: 70%;
    display: flex; }
    @media (max-width: 68.25667em) {
      .ra-provider-item .ra-provider-item-content {
        width: 100%; } }
    @media (max-width: 56.87889em) {
      .ra-provider-item .ra-provider-item-content {
        display: block; } }
    .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-1 {
      padding: 1.33333rem 2.22222rem 0;
      width: 100%;
      border-left: 1px solid #dadada;
      border-right: 1px solid #dadada; }
    .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 {
      padding: 1.33333rem 2.22222rem;
      width: 74%; }
      @media (max-width: 56.87889em) {
        .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 {
          width: 100%;
          display: block; } }
      .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-expanded-list {
        margin-top: 1.33333rem; }
      .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action {
        margin: 2.44444rem auto;
        text-align: center;
        width: 100%; }
        .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action.expanded:not(.ra-provider-hidden-button) {
          margin-bottom: 4.44444rem; }
        .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action.expanded.ra-provider-hidden-button {
          margin-top: 27.5px;
          margin-bottom: 27.5px; }
        .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action.ra-provider-hidden-button {
          margin-top: 65px; }
        @media (max-width: 56.87889em) {
          .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action {
            padding: 2.22222rem;
            background: #f8f8f8;
            margin-top: 1.33333rem !important;
            margin-bottom: 1.33333rem !important; } }
        .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action button {
          margin-top: 2.66667rem; }
          .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action button.ra-provider-extern-link {
            background: #1789e6;
            border-color: #1789e6; }
            .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action button.ra-provider-extern-link:hover {
              opacity: 0.8; }
            .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action button.ra-provider-extern-link svg {
              margin-left: 0.88889rem;
              display: inline-block;
              font-size: 1.6rem;
              top: 0.08889rem;
              left: inherit; }
              .ra-provider-item .ra-provider-item-content > div.ra-provider-item-content-2 .ra-provider-action button.ra-provider-extern-link svg path {
                fill: #fff; }

.ra-provider-expanded-list .ra-provider-expanded-item-desc {
  color: #999;
  font-size: 1.24444rem; }

.ra-provider-expanded-list .ra-provider-expanded-item {
  margin-bottom: 1.55556rem; }
  .ra-provider-expanded-list .ra-provider-expanded-item.marked > span:not(:first-child) {
    display: inline-block;
    background: #fffbd8; }
  .ra-provider-expanded-list .ra-provider-expanded-item span:first-child {
    display: block;
    font-family: 'Open Sans';
    font-weight: 600;
    color: #595959;
    font-size: 1.42222rem; }
  .ra-provider-expanded-list .ra-provider-expanded-item span:not(:first-child) {
    display: block;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 1.42222rem; }
  .ra-provider-expanded-list .ra-provider-expanded-item span.provider-block {
    position: relative;
    font-family: 'Open Sans';
    font-weight: 400; }
    .ra-provider-expanded-list .ra-provider-expanded-item span.provider-block:not(:last-child) {
      margin-bottom: 0.31111rem; }
    .ra-provider-expanded-list .ra-provider-expanded-item span.provider-block.numeral {
      padding-left: 1.55556rem; }
      .ra-provider-expanded-list .ra-provider-expanded-item span.provider-block.numeral > span {
        font-family: 'Open Sans';
        font-weight: 400;
        position: absolute;
        left: 0;
        top: 0; }
  .ra-provider-expanded-list .ra-provider-expanded-item span.ra-provider-router {
    font-family: 'Open Sans';
    font-weight: 400; }
    .ra-provider-expanded-list .ra-provider-expanded-item span.ra-provider-router .ra-provider-router-select {
      font-family: 'Open Sans';
      font-weight: 400;
      margin-bottom: 0.88889rem; }

.ra-provider-description-show-more {
  padding: 1.33333rem 0;
  cursor: pointer;
  transition: 0.3s; }
  .ra-provider-description-show-more .show-more-text {
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 1.42222rem;
    color: #3487f7; }
  @media (max-width: 56.87889em) {
    .ra-provider-description-show-more {
      display: none !important; } }
  .ra-provider-description-show-more .expanded svg {
    transform: rotate(270deg); }
  .ra-provider-description-show-more .not-expanded svg {
    transform: rotate(90deg); }
  .ra-provider-description-show-more svg {
    position: relative;
    top: 1.5px;
    fill: #3487f7;
    margin-left: 7.5px;
    transform: rotate(90deg);
    transition: 0.5s;
    font-size: 12px; }
  .ra-provider-description-show-more:hover {
    color: #79b0fb; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-slider-container {
  position: relative; }
  .ra-slider-container .ra-slider {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 1.33333rem;
    border-radius: 1px;
    background: #dadada;
    outline: none;
    cursor: pointer; }
    .ra-slider-container .ra-slider.ra-slider-disabled {
      cursor: not-allowed; }
    .ra-slider-container .ra-slider::-webkit-slider-thumb {
      opacity: 0; }
    .ra-slider-container .ra-slider::-moz-range-thumb {
      opacity: 0; }
  .ra-slider-container .ra-slider-pattern {
    height: 1.42222rem;
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    background-image: repeating-linear-gradient(45deg, #73c644, #73c644 15px, #61ab3b 15px, #61ab3b 30px);
    pointer-events: none; }
    .ra-slider-container .ra-slider-pattern:after {
      content: ' ';
      display: block;
      position: absolute;
      width: 2.22222rem;
      height: 2.22222rem;
      border-radius: 50%;
      background: #fff;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%); }
    .ra-slider-container .ra-slider-pattern.ra-slider-pattern-first:after {
      transform: translate(0, -50%); }
    .ra-slider-container .ra-slider-pattern.ra-slider-pattern-last:after {
      transform: translate(-100%, -50%); }
  .ra-slider-container .ra-slider-direction {
    display: flex;
    margin-top: 0.88889rem; }
    .ra-slider-container .ra-slider-direction span {
      font-size: 1.06667rem;
      white-space: nowrap;
      color: #a39791;
      width: 100%;
      display: block; }
      .ra-slider-container .ra-slider-direction span:last-child {
        width: auto; }

.ra-slider-hint {
  position: relative; }
  .ra-slider-hint:hover .ra-slider-hint-text {
    display: block; }
  .ra-slider-hint .ra-slider-hint-text {
    width: 22.22222rem;
    font-family: 'Open Sans';
    font-weight: 400;
    padding: 0.88889rem;
    font-size: 1.24444rem;
    background: black;
    color: #fff;
    border-radius: 4px;
    display: none;
    position: absolute;
    right: 1.33333rem;
    top: -7.11111rem; }
    .ra-slider-hint .ra-slider-hint-text:after {
      content: ' ';
      clear: both;
      display: block;
      position: absolute;
      bottom: -0.44444rem;
      right: 2.66667rem;
      transform: rotate(45deg);
      padding: 0.44444rem;
      background: black; }
  .ra-slider-hint svg {
    vertical-align: -0.17778rem; }
    .ra-slider-hint svg path {
      color: #ccc; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

.ra-checkbox-group .ra-checkbox-empty {
  color: #a39791; }

.ra-checkbox-group .ra-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative; }
  .ra-checkbox-group .ra-checkbox:not(:last-child) {
    margin-bottom: 0.53333rem; }
  .ra-checkbox-group .ra-checkbox.ra-checkbox-disabled {
    cursor: not-allowed; }
    .ra-checkbox-group .ra-checkbox.ra-checkbox-disabled .ra-checkbox-label span {
      color: #a39791; }
    .ra-checkbox-group .ra-checkbox.ra-checkbox-disabled .ra-checkbox-checkmark {
      background: #dadada; }
  .ra-checkbox-group .ra-checkbox input {
    /* Hide the browser's default checkbox */
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .ra-checkbox-group .ra-checkbox .ra-checkbox-label {
    margin-left: 2rem;
    display: block; }
    .ra-checkbox-group .ra-checkbox .ra-checkbox-label .ra-checkbox-loading {
      background: linear-gradient(-90deg, #b6b6b6 0%, #ffffff 50%, #b6b6b6 100%) !important;
      background-size: 400% 400% !important;
      -webkit-animation: ra-pulse 2s ease-in-out infinite;
              animation: ra-pulse 2s ease-in-out infinite;
      opacity: 0.7;
      border-radius: 2px; }
      .ra-checkbox-group .ra-checkbox .ra-checkbox-label .ra-checkbox-loading span {
        color: transparent !important; }
    .ra-checkbox-group .ra-checkbox .ra-checkbox-label span {
      color: #ffffff;
      font-size: 1.51111rem; }
  .ra-checkbox-group .ra-checkbox .ra-checkbox-checkmark {
    position: absolute;
    top: 0.44444rem;
    left: 0;
    height: 1.33333rem;
    width: 1.33333rem;
    background-color: #fff;
    border-radius: 3px; }
    .ra-checkbox-group .ra-checkbox .ra-checkbox-checkmark:after {
      content: '';
      position: absolute;
      display: none;
      left: 0.44444rem;
      top: 0.08889rem;
      width: 0.44444rem;
      height: 0.88889rem;
      border: solid #76c155;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg); }
  .ra-checkbox-group .ra-checkbox input:checked ~ .ra-checkbox-checkmark:after {
    display: block; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

@-webkit-keyframes ra-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes ra-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.ra-autocomplete-mask {
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%; }

.ra-autocomplete {
  z-index: 2;
  position: relative; }
  .ra-autocomplete .ra-autocomplete-suggestions-message {
    margin-top: 0.22222rem;
    background: #fffbd8;
    color: #000;
    border-bottom: 1px solid #d9d9d9;
    padding: 0.44444rem 1.77778rem;
    font-size: 1.42222rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .ra-autocomplete .ra-autocomplete-input {
    width: 100%;
    height: 6.22222rem;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    padding: 0.44444rem 1.77778rem;
    border-radius: 4px;
    font-size: 1.6rem;
    outline: none !important; }
    .ra-autocomplete .ra-autocomplete-input::-webkit-input-placeholder {
      color: #bfbfbf; }
    .ra-autocomplete .ra-autocomplete-input::-ms-input-placeholder {
      color: #bfbfbf; }
    .ra-autocomplete .ra-autocomplete-input::placeholder {
      color: #bfbfbf; }
  .ra-autocomplete .ra-autocomplete-suggestions {
    position: absolute;
    left: 0;
    top: 6.44444rem;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.1); }
    .ra-autocomplete .ra-autocomplete-suggestions.alternative {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      top: 9.51111rem; }
    .ra-autocomplete .ra-autocomplete-suggestions li {
      position: relative;
      display: block;
      padding: 0.44444rem 1.77778rem;
      font-size: 1.42222rem; }
      .ra-autocomplete .ra-autocomplete-suggestions li:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px; }
      .ra-autocomplete .ra-autocomplete-suggestions li:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px; }
      .ra-autocomplete .ra-autocomplete-suggestions li.active, .ra-autocomplete .ra-autocomplete-suggestions li:hover {
        background-color: #f6fff0;
        cursor: pointer;
        color: #464646; }
      .ra-autocomplete .ra-autocomplete-suggestions li .ra-loader-suggestion-spin {
        position: absolute;
        right: 15px;
        top: 8.5px;
        display: inline-block;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-top: 1px solid #464646;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        -webkit-animation: ra-spin 1s linear infinite;
                animation: ra-spin 1s linear infinite; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-seeking-form.ra-seeking-search {
  display: flex;
  margin: 2.22222rem 0 0; }
  .ra-seeking-form.ra-seeking-search .ra-seeking-autocomplete-label {
    display: none; }
  .ra-seeking-form.ra-seeking-search .ra-button {
    min-width: auto;
    height: 5.33333rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .ra-seeking-form.ra-seeking-search .ra-button .ra-button-text {
      font-size: 1.77778rem; }
    @media (max-width: 68.25667em) {
      .ra-seeking-form.ra-seeking-search .ra-button {
        padding-left: 2.22222rem;
        padding-right: 2.22222rem; }
        .ra-seeking-form.ra-seeking-search .ra-button .ra-button-text {
          font-size: 1.42222rem; } }
  .ra-seeking-form.ra-seeking-search .ra-seeking-autocomplete {
    display: block;
    width: 100%;
    height: 5.33333rem; }
    .ra-seeking-form.ra-seeking-search .ra-seeking-autocomplete .ra-autocomplete-input {
      height: 5.33333rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

.ra-seeking-form .ra-seeking-autocomplete {
  position: relative; }
  .ra-seeking-form .ra-seeking-autocomplete .ra-seeking-close {
    position: absolute;
    right: 1.33333rem;
    top: calc(50% - 1px);
    transform: translateY(-50%);
    z-index: 2;
    color: #595959;
    font-size: 3.2rem;
    font-weight: 100;
    cursor: pointer; }
    .ra-seeking-form .ra-seeking-autocomplete .ra-seeking-close:hover {
      color: #595959; }

.ra-seeking-form .ra-seeking-autocomplete-label {
  font-size: 1.77778rem;
  color: #fff;
  font-family: 'Ubuntu';
  font-weight: 500;
  margin-bottom: 0.44444rem; }

.ra-seeking-form .ra-seeking-error {
  color: red;
  text-align: left;
  margin-top: 0.22222rem; }

.ra-seeking-card p {
  color: #fff;
  margin: 0 0 4.44444rem;
  font-size: 1.77778rem;
  font-family: 'Ubuntu';
  font-weight: 400; }
  @media (max-width: 68.25667em) {
    .ra-seeking-card p {
      font-size: 1.42222rem;
      margin: 0 0 2.22222rem; } }

.ra-seeking-alternative-title {
  cursor: not-allowed;
  background: #fafaf5 !important;
  color: #ccc !important; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes width-animation {
  from {
    width: 22.22222rem; }
  to {
    width: 100%; } }

@keyframes width-animation {
  from {
    width: 22.22222rem; }
  to {
    width: 100%; } }

@-webkit-keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.checkout-content {
  margin-top: 1.38889rem; }
  .checkout-content .button {
    margin-top: 0.27778rem; }
    @media (max-width: 47.99em) {
      .checkout-content .button {
        margin-top: -1.38889rem;
        width: 100%;
        display: block; } }
  .checkout-content img {
    max-height: 4.16667rem; }
  .checkout-content .checkout-price {
    text-align: right;
    display: flex;
    flex-direction: column-reverse; }
  @media (max-width: 47.99em) {
    .checkout-content .ant-row-flex {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap; } }
  @media (max-width: 47.99em) {
    .checkout-content .ant-row-flex .ant-col {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      float: inherit; }
      .checkout-content .ant-row-flex .ant-col:not(:first-child):not(.ant-form-item-control-wrapper) {
        margin-top: 2.22222rem; } }
  .checkout-content .checkout-privacy {
    margin: 1.38889rem 0; }
    .checkout-content .checkout-privacy > span {
      position: relative;
      display: block;
      padding-left: 1.80556rem;
      font-size: 0.77778rem; }
      .checkout-content .checkout-privacy > span:not(:last-child) {
        margin-bottom: 0.83333rem; }
      .checkout-content .checkout-privacy > span i {
        font-size: 1.33333rem;
        position: absolute;
        width: 1.16667rem;
        height: 1.16667rem;
        top: -2.5px;
        left: 0;
        color: #25aae1; }

.checkout-read-only {
  color: #595959; }

.zh-card-header.zh-card-header-show-address .zh-card-header-content {
  opacity: 0;
  -webkit-animation: fade-out 0.25s;
          animation: fade-out 0.25s; }

.zh-card-header.zh-card-header-show-address .zh-card-header-toolbar {
  right: 0;
  position: absolute;
  padding: 0;
  -webkit-animation: width-animation 0.25s;
          animation: width-animation 0.25s;
  width: 100%; }

.zh-card-header.zh-card-header-show-address .ra-autocomplete .ra-autocomplete-suggestions {
  top: 3.47222rem; }
  .zh-card-header.zh-card-header-show-address .ra-autocomplete .ra-autocomplete-suggestions.alternative {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: 5.41667rem; }

.zh-card-header .zh-card-header-content {
  padding: 0.41667rem 0; }
  .zh-card-header .zh-card-header-content .header-go-back {
    display: inline-block;
    margin-bottom: 1.66667rem;
    color: #fff;
    font-size: 1.33333rem;
    padding-left: 1.11111rem;
    cursor: pointer;
    transition: 0.1s; }
    .zh-card-header .zh-card-header-content .header-go-back:hover {
      opacity: 0.9; }
    .zh-card-header .zh-card-header-content .header-go-back .go-back-arrow {
      margin-left: 0 !important;
      position: absolute;
      left: 0.13889rem;
      top: 0.61111rem;
      transform: rotate(180deg);
      display: inline-block; }
  .zh-card-header .zh-card-header-content h3.title {
    margin-bottom: 0;
    font-size: 3.44444rem; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.logger-container {
  padding-top: 1.66667rem; }
  .logger-container pre {
    background: #fff;
    padding: 1.11111rem; }
  .logger-container .logger-toggler {
    text-transform: uppercase;
    text-align: center;
    margin-top: -0.83333rem;
    border-top: 1px solid #ccc;
    background: #fff;
    padding: 0.55556rem 1.11111rem;
    font-family: 'Ubuntu';
    font-weight: 500; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.informer-table .ant-table-thead > tr:first-child > th:first-child {
  border-radius: 0; }

.informer-table .ant-table-thead > tr > th {
  vertical-align: top;
  padding: 0.69444rem 0.83333rem;
  background: #eaf0f6;
  color: rgba(0, 0, 0, 0.85);
  text-transform: uppercase;
  font-family: 'Ubuntu';
  font-weight: 500; }

.informer-table .ant-table-tbody > tr:nth-child(odd) {
  background: #fff; }

.informer-table .ant-table-tbody > tr > td {
  vertical-align: top;
  padding: 0.55556rem 0.83333rem;
  font-family: 'Ubuntu';
  font-weight: 400; }
  .informer-table .ant-table-tbody > tr > td a {
    color: #063467;
    font-family: 'Ubuntu';
    font-weight: 500; }
    .informer-table .ant-table-tbody > tr > td a:hover {
      color: #084a96; }

.informer-table .ant-table-pagination {
  margin-right: 1.38889rem; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.signin-form {
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 2.77778rem;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px; }
  .signin-form h1 {
    text-align: center;
    margin-bottom: 1.38889rem; }
  .signin-form .signin-form-button {
    margin-top: 0.83333rem;
    width: 100%; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.nomenclature-set-default {
  display: inline-block;
  margin-right: 0.69444rem; }
  .nomenclature-set-default .nomenclature-set-default-text {
    margin-left: 0.27778rem;
    font-size: 0.77778rem;
    vertical-align: 0; }


@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.order-resend {
  font-family: 'Ubuntu';
  font-weight: 500; }
  .order-resend.unactive {
    color: #ccc;
    cursor: not-allowed; }
  .order-resend.active {
    color: #063467;
    cursor: pointer; }
    .order-resend.active:hover {
      color: #084a96; }


@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.provider-dafault-logo {
  width: 22.22222rem;
  height: 16.66667rem;
  margin-bottom: 1.38889rem; }
  .provider-dafault-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.provider-fields .ant-form-item-children {
  display: flex;
  flex-wrap: wrap; }

.provider-fields .provider-field {
  width: calc(50% - 4.75px);
  position: relative;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  font-size: 1.11111rem;
  margin-right: 0.27778rem;
  padding: 0.83333rem 0.83333rem 0;
  position: relative; }
  .provider-fields .provider-field .provider-field-delete {
    position: absolute;
    right: 15px;
    top: 15px; }
  .provider-fields .provider-field:not(:last-child) {
    margin-bottom: 0.27778rem; }
  .provider-fields .provider-field:last-child {
    padding-top: 0;
    text-align: center;
    width: 100%; }
    .provider-fields .provider-field:last-child .provider-field-action {
      border-top-color: transparent; }
  .provider-fields .provider-field .provider-field-action {
    font-family: 'Ubuntu';
    font-weight: 500;
    cursor: pointer;
    transition: 0.1s;
    display: inline-block;
    line-height: 1.5; }
    .provider-fields .provider-field .provider-field-action i {
      vertical-align: 0.5px;
      margin-left: 0.55556rem;
      font-size: 0.55556rem; }
    .provider-fields .provider-field .provider-field-action:hover {
      color: #79e34b; }
  .provider-fields .provider-field b {
    display: block;
    color: #063467;
    line-height: 1.5;
    padding-right: 25px; }

.provider-picker-input .ant-form-item-children {
  display: flex; }

.provider-picker-input .provider-picker-button {
  height: 2rem;
  width: 4.44444rem;
  border-radius: 4px;
  border: 3px solid #d9d9d9;
  background: transparent;
  margin-left: 0.27778rem; }

.provider-picker-input .provider-picker-wrapper {
  position: absolute;
  right: 0;
  z-index: 2; }
  .provider-picker-input .provider-picker-wrapper.left {
    left: 0; }
  .provider-picker-input .provider-picker-wrapper .provider-picker-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.button-long-text span {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0.83333rem 0rem; }

body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

@-webkit-keyframes ra-width-animation {
  from {
    width: 35.55556rem; }
  to {
    width: 100%; } }

@keyframes ra-width-animation {
  from {
    width: 35.55556rem; }
  to {
    width: 100%; } }

@-webkit-keyframes ra-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes ra-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes ra-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ra-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.zh-card-header {
  position: relative;
  display: flex; }
  @media (max-width: 68.25667em) {
    .zh-card-header {
      display: block; } }
  .zh-card-header.zh-card-header-show-address .zh-card-header-content {
    opacity: 0;
    -webkit-animation: ra-fade-out 0.25s;
            animation: ra-fade-out 0.25s; }
    @media (max-width: 68.25667em) {
      .zh-card-header.zh-card-header-show-address .zh-card-header-content {
        opacity: 1;
        -webkit-animation: none;
                animation: none; } }
  .zh-card-header.zh-card-header-show-address .zh-card-header-toolbar {
    right: 0;
    position: absolute;
    padding: 0;
    -webkit-animation: ra-width-animation 0.25s;
            animation: ra-width-animation 0.25s;
    width: 100%; }
    @media (max-width: 68.25667em) {
      .zh-card-header.zh-card-header-show-address .zh-card-header-toolbar {
        margin-bottom: 3.28889rem;
        -webkit-animation: none;
                animation: none;
        position: relative; } }
  .zh-card-header.zh-card-header-show-address .ra-autocomplete .ra-autocomplete-suggestions {
    top: 5.55556rem; }
    .zh-card-header.zh-card-header-show-address .ra-autocomplete .ra-autocomplete-suggestions.alternative {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      top: 8.66667rem; }
  .zh-card-header .zh-card-header-content {
    width: 100%; }
    .zh-card-header .zh-card-header-content h3.title {
      font-size: 3.37778rem;
      color: #fff !important;
      font-family: 'Ubuntu';
      font-weight: 500;
      margin: 0 0 0.66667rem; }
      @media (max-width: 68.25667em) {
        .zh-card-header .zh-card-header-content h3.title {
          font-size: 3.73333rem; } }
      @media (max-width: 68.25667em) {
        .zh-card-header .zh-card-header-content h3.title {
          font-size: 2.84444rem; } }
    .zh-card-header .zh-card-header-content p {
      margin: 0 0 4.44444rem; }
      .zh-card-header .zh-card-header-content p span {
        font-size: 1.77778rem;
        color: #ddd;
        font-family: 'Open Sans';
        font-weight: 400; }
      .zh-card-header .zh-card-header-content p.small {
        font-size: 1.24444rem;
        margin: 0 0 2.22222rem; }
      .zh-card-header .zh-card-header-content p.no-margin {
        margin: 0; }
      .zh-card-header .zh-card-header-content p b,
      .zh-card-header .zh-card-header-content p strong {
        color: #75c742; }
      .zh-card-header .zh-card-header-content p > span {
        display: block; }
        .zh-card-header .zh-card-header-content p > span.parsed-time.parsed-time-loading strong {
          color: #5f5f5f; }
        .zh-card-header .zh-card-header-content p > span.parsed-time strong {
          color: #fff;
          font-weight: 600; }
      @media (max-width: 68.25667em) {
        .zh-card-header .zh-card-header-content p {
          font-size: 1.42222rem;
          margin-bottom: 2.22222rem; }
          .zh-card-header .zh-card-header-content p.small {
            margin-bottom: 1.77778rem; } }
    .zh-card-header .zh-card-header-content .ra-parsing-loader {
      max-width: 57.33333rem; }
      @media (max-width: 68.25667em) {
        .zh-card-header .zh-card-header-content .ra-parsing-loader {
          max-width: 100%; } }
      .zh-card-header .zh-card-header-content .ra-parsing-loader .ra-parsing-loader-text {
        display: block;
        font-size: 1.77778rem;
        margin-bottom: 0.88889rem; }
        .zh-card-header .zh-card-header-content .ra-parsing-loader .ra-parsing-loader-text > span {
          color: #ddd;
          font-family: 'Open Sans';
          font-weight: 600;
          margin-left: 0.88889rem; }
  .zh-card-header .zh-card-header-toolbar {
    width: auto; }
    @media (max-width: 68.25667em) {
      .zh-card-header .zh-card-header-toolbar {
        padding: 0; } }

.zh-card-header-divider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 1.95556rem 0;
  background: rgba(108, 108, 108, 0.75); }

.zh-card-header-filter {
  display: flex; }
  @media (max-width: 68.25667em) {
    .zh-card-header-filter {
      display: block; } }
  @media (max-width: 68.25667em) {
    .zh-card-header-filter > .zh-card-header-filter-item {
      width: 100% !important; } }
  .zh-card-header-filter > .zh-card-header-filter-item:not(:first-child) {
    width: 17.5%;
    min-width: 150px; }
  .zh-card-header-filter > .zh-card-header-filter-item:not(:last-child) {
    margin-right: 2.22222rem; }
    @media (max-width: 68.25667em) {
      .zh-card-header-filter > .zh-card-header-filter-item:not(:last-child) {
        margin-bottom: 2.22222rem; } }
  .zh-card-header-filter > .zh-card-header-filter-item:first-child {
    width: 100%;
    margin-right: 6.66667rem; }
    @media (max-width: 91.01222em) {
      .zh-card-header-filter > .zh-card-header-filter-item:first-child {
        margin-right: 3.11111rem; } }
    .zh-card-header-filter > .zh-card-header-filter-item:first-child .ra-slider-container {
      max-width: 339px; }
      @media (max-width: 68.25667em) {
        .zh-card-header-filter > .zh-card-header-filter-item:first-child .ra-slider-container {
          max-width: 100%; } }
  .zh-card-header-filter > .zh-card-header-filter-item:last-child {
    width: 12.5%; }
  .zh-card-header-filter > .zh-card-header-filter-item .label {
    font-family: 'Ubuntu';
    font-weight: 500;
    color: #fff;
    font-size: 1.55556rem;
    display: block;
    margin-bottom: 0.44444rem; }
    .zh-card-header-filter > .zh-card-header-filter-item .label span {
      font-family: 'Ubuntu';
      font-weight: 500;
      color: #fff;
      font-size: 1.55556rem; }
    .zh-card-header-filter > .zh-card-header-filter-item .label .regular {
      font-family: 'Ubuntu';
      font-weight: 400;
      color: #ddd; }
    .zh-card-header-filter > .zh-card-header-filter-item .label b {
      font-family: 'Ubuntu';
      font-weight: 500;
      font-size: 1.55556rem;
      color: #75c742; }
  .zh-card-header-filter > .zh-card-header-filter-item .ra-select-wrapper {
    margin-top: 0.88889rem; }
  .zh-card-header-filter > .zh-card-header-filter-item .ra-slider-container {
    margin-top: 0.66667rem; }
  .zh-card-header-filter > .zh-card-header-filter-item .label-desc {
    color: #fff;
    display: block;
    margin: 10px 0 12.5px;
    font-size: 1.42222rem; }
    .zh-card-header-filter > .zh-card-header-filter-item .label-desc b {
      color: #75c742; }
  .zh-card-header-filter > .zh-card-header-filter-item .ant-select-selection {
    height: 46px;
    min-width: 143px;
    border-radius: 10px; }
    .zh-card-header-filter > .zh-card-header-filter-item .ant-select-selection .ant-select-selection__rendered {
      line-height: 45px; }
    .zh-card-header-filter > .zh-card-header-filter-item .ant-select-selection .ant-select-arrow {
      color: #75c742; }

.ra-results-fallback {
  text-align: center;
  color: #fff;
  height: 0px;
  overflow: hidden;
  transition: 1s; }
  .ra-results-fallback .ra-results-fallback-text {
    font-size: 1.68889rem;
    margin: 0 auto;
    padding: 0.44444rem 2.22222rem 2.66667rem;
    max-width: 66.66667rem;
    -webkit-animation: ra-fade-in 1s;
            animation: ra-fade-in 1s; }
  .ra-results-fallback.active {
    height: auto;
    margin: 0 0 2.22222rem;
    border-bottom: 1px solid rgba(108, 108, 108, 0.75); }
  .ra-results-fallback a {
    color: #5fa0f9; }

@charset "UTF-8";
body #results-app {
  font-family: 'Open Sans';
  font-weight: 400;
  box-sizing: border-box; }
  body #results-app * {
    box-sizing: border-box; }

#results-app .skeleton,
#results-app .skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@-webkit-keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-fluid {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: ra-pulse 2s ease-in-out infinite;
          animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7; }

@keyframes ra-pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

#results-app .skeleton-auto {
  height: auto !important; }

#results-app .skeleton-text {
  color: transparent !important; }
  #results-app .skeleton-text svg path {
    fill: transparent !important; }

#results-app .skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

#results-app .grey {
  color: #ddd !important; }

#results-app .capitalize {
  text-transform: capitalize; }

#results-app .title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #ababab; }

#results-app .no-padding {
  padding: 0; }

#results-app .no-margin {
  margin: 0; }

#results-app .empty-text {
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  padding: 0;
  margin: 6.22222rem 1.33333rem 2.22222rem; }

#results-app .center {
  text-align: center; }

.ra-seeking-card h1 {
  color: #fff;
  font-size: 5.68889rem;
  font-family: 'Ubuntu';
  font-weight: 500;
  margin: 0 0 0.66667rem; }
  @media (max-width: 68.25667em) {
    .ra-seeking-card h1 {
      font-size: 3.46667rem; } }

.ra-seeking-card button {
  margin: 2.66667rem auto;
  display: block; }

.ra-seeking-card .ra-seeking-privacy {
  text-align: center;
  font-size: 1.06667rem; }
  .ra-seeking-card .ra-seeking-privacy span {
    color: #fff; }
  .ra-seeking-card .ra-seeking-privacy svg {
    vertical-align: -0.44444rem;
    margin-right: 0.44444rem; }

.ra-seeking-suggestion span:not(:last-child) {
  margin-right: 5px; }
  .ra-seeking-suggestion span:not(:last-child):after {
    content: ' · ';
    color: #ccc;
    clear: both;
    display: inline-block;
    margin-left: 5px; }

.ra-seeking-suggestion span.active {
  font-family: 'Ubuntu';
  font-weight: 500; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ant-form.vertical-form .ant-form-item-label {
  padding: 0.55556rem 0rem; }

.ant-form.vertical-form .time i {
  top: 10px; }

.ant-form .form-item-button .ant-form-item-children {
  display: flex; }
  .ant-form .form-item-button .ant-form-item-children button {
    width: 100%;
    max-width: auto;
    min-width: auto; }
  .ant-form .form-item-button .ant-form-item-children > button,
  .ant-form .form-item-button .ant-form-item-children > a {
    display: block;
    width: 100%; }
    .ant-form .form-item-button .ant-form-item-children > button:not(:last-child),
    .ant-form .form-item-button .ant-form-item-children > a:not(:last-child) {
      margin-right: 0.97222rem;
      width: calc(100% + 17.5px); }

.ant-form .ant-form-explain {
  margin-top: 0;
  font-size: 0.77778rem; }

.ant-form .ant-input-number,
.ant-form .ant-calendar-picker {
  width: 100%; }

.ant-form .has-error .ant-select-auto-complete.ant-select .ant-select-selection {
  border-color: #f5222d; }

.ant-form .ant-form-item.form-item-compact {
  margin-bottom: 0.13889rem; }

.ant-form .ant-form-item button:not(:last-child),
.ant-form .ant-form-item a {
  margin-right: 1.11111rem; }

.ant-form .ant-form-item:last-child {
  margin-bottom: 0; }

.ant-form .ant-form-item-label {
  text-align: left;
  font-family: 'Ubuntu';
  font-weight: 500;
  line-height: inherit; }
  .ant-form .ant-form-item-label .anticon {
    margin-left: 2.5px;
    vertical-align: -2px;
    color: #ccc; }
  .ant-form .ant-form-item-label small {
    color: #f5222d;
    margin-left: 2.5px;
    vertical-align: 2px; }

.ant-form .ant-select-auto-complete.ant-select .ant-select-selection {
  border: 1px solid #d9d9d9; }

.ant-form .ant-select-auto-complete.ant-select .ant-select-selection__placeholder,
.ant-form .ant-select-auto-complete.ant-select .ant-input {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important; }

.ant-form .ant-select-auto-complete.ant-select .ant-input {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 0 !important;
  box-shadow: none !important; }

.form-invert .ant-form-item-label {
  font-family: 'Ubuntu';
  font-weight: 400;
  line-height: inherit; }
  .form-invert .ant-form-item-label > label {
    color: #fff; }

.form-short .ant-form-item:not(.form-item-fluid) {
  max-width: 12.22222rem;
  margin-bottom: 1.11111rem; }
  @media (max-width: 47.99em) {
    .form-short .ant-form-item:not(.form-item-fluid) {
      max-width: 100%; } }

.form-default .ant-form-item-label {
  font-family: 'Ubuntu';
  font-weight: 500;
  margin-bottom: 0.13889rem; }
  @media (max-width: 47.99em) {
    .form-default .ant-form-item-label {
      margin-bottom: 0.05556rem; } }
  .form-default .ant-form-item-label > label {
    font-size: 0.88889rem; }
    @media (max-width: 47.99em) {
      .form-default .ant-form-item-label > label {
        font-size: 14px; } }

.form-default .ant-select {
  font-size: 0.88889rem; }
  .form-default .ant-select .ant-select-selection--single {
    height: 2.05556rem; }
    .form-default .ant-select .ant-select-selection--single .ant-select-selection__rendered {
      margin: 0 0.41667rem;
      line-height: 1.94444rem; }

.form-big .ant-form-item-label {
  font-family: 'Ubuntu';
  font-weight: 500;
  margin-bottom: 0.41667rem; }
  .form-big .ant-form-item-label > label {
    font-size: 1.11111rem; }

.form-big .ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: 3.88889rem; }

.form-big .ant-select {
  font-size: 1rem; }
  .form-big .ant-select .ant-select-selection--single {
    height: 3.88889rem; }
    .form-big .ant-select .ant-select-selection--single .ant-select-selection__rendered {
      margin: 0 1.25rem;
      line-height: 3.77778rem; }

.form-item-glue .ant-form-item-children {
  display: flex; }
  @media (max-width: 47.99em) {
    .form-item-glue .ant-form-item-children {
      display: block; } }
  @media (min-width: 48em) {
    .form-item-glue .ant-form-item-children .ant-select:first-child .ant-select-selection {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: 0; } }
  @media (min-width: 48em) {
    .form-item-glue .ant-form-item-children .ant-select:nth-child(2) .ant-select-selection {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px; } }
  .form-item-glue .ant-form-item-children .form-item-glue-short {
    width: 40%; }
    @media (max-width: 47.99em) {
      .form-item-glue .ant-form-item-children .form-item-glue-short {
        width: 100%;
        margin-top: 0.27778rem; }
        .form-item-glue .ant-form-item-children .form-item-glue-short .ant-select-selection--single {
          height: 36px; }
          .form-item-glue .ant-form-item-children .form-item-glue-short .ant-select-selection--single .ant-select-selection__rendered {
            margin: 0 1.25rem;
            line-height: 2rem; } }

.ant-divider {
  background: #6c6c6c; }
  .ant-divider.divider-grey {
    background: #dadada; }

.skeleton,
.skeleton-auto {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: pulse 2s ease-in-out infinite;
          animation: pulse 2s ease-in-out infinite; }

@-webkit-keyframes pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

@keyframes pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

.skeleton-fluid {
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%) !important;
  background-size: 400% 400% !important;
  -webkit-animation: pulse 2s ease-in-out infinite;
          animation: pulse 2s ease-in-out infinite; }

@keyframes pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

.skeleton-auto {
  height: auto !important; }

.skeleton-text {
  color: transparent !important; }

.skeleton-img {
  -webkit-filter: blur(5px) grayscale(100%) !important;
          filter: blur(5px) grayscale(100%) !important; }

.ant-checkbox-wrapper {
  display: flex !important; }
  .ant-checkbox-wrapper > span {
    display: block;
    line-height: 1.5; }
  .ant-checkbox-wrapper .ant-checkbox {
    width: 16px;
    height: 16px;
    top: 2px; }

.checkbox-small .ant-checkbox {
  top: 0px; }

.checkbox-small > span {
  font-size: 11px; }

.modal-footer-center .ant-modal-footer {
  text-align: center; }

html,
body {
  font-size: 10px; }

body > #root {
  min-height: 100vh;
  font-family: 'Open Sans';
  font-weight: 400; }

h1,
h2,
h3,
h4 {
  line-height: normal;
  line-height: initial; }

.center {
  text-align: center; }

.block {
  width: 100%; }

.grey {
  color: #ddd !important; }

.capitalize {
  text-transform: capitalize; }

.mt-5 {
  margin-top: 0.27778rem !important; }

.mt-10 {
  margin-top: 0.55556rem !important; }

.mb-15 {
  margin-bottom: 0.83333rem !important; }

.mb-20 {
  margin-bottom: 1.11111rem !important; }

.title {
  font-family: 'Ubuntu';
  font-weight: 500;
  color: #063467; }

.no-padding {
  padding: 0; }

.no-margin {
  margin: 0; }

.empty-text {
  color: #ccc;
  font-size: 1rem;
  text-align: center;
  padding: 0;
  margin: 3.88889rem 0.83333rem 1.38889rem; }

.iconed-text {
  display: block;
  position: relative;
  padding-left: 1.97222rem;
  color: #2a3846 !important; }
  .iconed-text svg,
  .iconed-text i {
    color: #a2bacd;
    position: absolute;
    left: 0;
    top: 1.5px;
    margin: 0 !important; }

@media (max-width: 47.99em) {
  table td {
    display: block; } }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

