@import '../../index';

@keyframes ra-width-animation {
  from {
    width: rem(400px);
  }
  to {
    width: 100%;
  }
}

@keyframes ra-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes ra-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.zh-card-header {
  position: relative;
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  &.zh-card-header-show-address {
    .zh-card-header-content {
      opacity: 0;
      animation: ra-fade-out 0.25s;

      @include mq($until: small-desktop) {
        opacity: 1;
        animation: none;
      }
    }

    .zh-card-header-toolbar {
      right: 0;
      position: absolute;
      padding: 0;
      animation: ra-width-animation 0.25s;
      width: 100%;

      @include mq($until: small-desktop) {
        margin-bottom: rem(37px);
        animation: none;
        position: relative;
      }
    }

    .ra-autocomplete .ra-autocomplete-suggestions {
      top: rem(62.5px);

      &.alternative {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        top: rem(97.5px);
      }
    }
  }

  .zh-card-header-content {
    width: 100%;

    h3.title {
      font-size: rem(38px);
      color: #fff !important;
      @include fontBold;
      margin: rem(0 0 7.5px);

      @include mq($until: small-desktop) {
        font-size: rem(42px);
      }

      @include mq($until: small-desktop) {
        font-size: rem(32px);
      }
    }

    p {
      margin: rem(0 0 50px);

      span {
        font-size: rem(20px);
        color: #ddd;
        @include fontOpenSansRegular;
      }

      &.small {
        font-size: rem(14px);
        margin: rem(0 0 25px);
      }

      &.no-margin {
        margin: 0;
      }

      b,
      strong {
        color: #75c742;
      }

      > span {
        display: block;

        &.parsed-time {
          &.parsed-time-loading strong {
            color: #5f5f5f;
          }

          strong {
            color: #fff;
            font-weight: 600;
          }
        }
      }

      @include mq($until: small-desktop) {
        font-size: rem(16px);
        margin-bottom: rem(25px);

        &.small {
          margin-bottom: rem(20px);
        }
      }
    }

    .ra-parsing-loader {
      max-width: rem(645px);

      @include mq($until: small-desktop) {
        max-width: 100%;
      }

      .ra-parsing-loader-text {
        display: block;
        font-size: rem(20px);
        margin-bottom: rem(10px);

        > span {
          color: #ddd;
          @include fontOpenSansSemiBold;
          margin-left: rem(10px);
        }
      }
    }
  }

  .zh-card-header-toolbar {
    width: auto;

    @include mq($until: small-desktop) {
      padding: 0;
    }
  }
}

.zh-card-header-divider {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: rem(22px 0);
  background: rgba(108, 108, 108, 0.75);
}

.zh-card-header-filter {
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  > .zh-card-header-filter-item {
    @include mq($until: small-desktop) {
      width: 100% !important;
    }

    &:not(:first-child) {
      width: 17.5%;
      min-width: 150px;
    }

    &:not(:last-child) {
      margin-right: rem(25px);

      @include mq($until: small-desktop) {
        margin-bottom: rem(25px);
      }
    }

    &:first-child {
      width: 100%;
      margin-right: rem(75px);

      @include mq($until: desktop) {
        margin-right: rem(35px);
      }

      .ra-slider-container {
        max-width: 339px;

        @include mq($until: small-desktop) {
          max-width: 100%;
        }
      }
    }

    &:last-child {
      width: 12.5%;
    }

    .label {
      @include fontSemiBold;
      color: #fff;
      font-size: rem(17.5px);
      display: block;
      margin-bottom: rem(5px);

      span {
        @include fontSemiBold;
        color: #fff;
        font-size: rem(17.5px);
      }

      .regular {
        @include fontRegular;
        color: #ddd;
      }

      b {
        @include fontSemiBold;
        font-size: rem(17.5px);
        color: #75c742;
      }
    }

    .ra-select-wrapper {
      margin-top: rem(10px);
    }

    .ra-slider-container {
      margin-top: rem(7.5px);
    }

    .label-desc {
      color: #fff;
      display: block;
      margin: (10px 0 12.5px);
      font-size: rem(16px);

      b {
        color: #75c742;
      }
    }

    .ant-select-selection {
      height: 46px;
      min-width: 143px;
      border-radius: 10px;

      .ant-select-selection__rendered {
        line-height: 45px;
      }

      .ant-select-arrow {
        color: #75c742;
      }
    }
  }
}

.ra-results-fallback {
  text-align: center;
  color: #fff;
  height: 0px;
  overflow: hidden;
  transition: 1s;

  .ra-results-fallback-text {
    font-size: rem(19px);
    margin: 0 auto;
    padding: rem(5px 25px 30px);
    max-width: rem(750px);
    animation: ra-fade-in 1s;
  }

  &.active {
    height: auto;
    margin: rem(0 0 25px);
    border-bottom: 1px solid rgba(108, 108, 108, 0.75);
  }

  a {
    color: #5fa0f9;
  }
}
