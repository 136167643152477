@import '../../../styles/helpers';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ra-loader-container {
  position: relative;

  .loader-spin {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    transform: translate(-50%, -50%);
    border: 6px solid rgba(243, 243, 243, 0.25);
    border-top: 6px solid #53d624;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
  }
}
