@import '../../index';

.ra-seeking-card {
  h1 {
    color: #fff;
    font-size: rem(64px);
    @include fontBold;
    margin: rem(0 0 7.5px);

    @include mq($until: small-desktop) {
      font-size: rem(39px);
    }
  }

  button {
    margin: rem(30px auto);
    display: block;
  }

  .ra-seeking-privacy {
    text-align: center;
    font-size: rem(12px);

    span {
      color: #fff;
    }

    svg {
      vertical-align: rem(-5px);
      margin-right: rem(5px);
    }
  }
}

.ra-seeking-suggestion {
  span {
    &:not(:last-child) {
      margin-right: 5px;

      &:after {
        content: ' · ';
        color: #ccc;
        clear: both;
        display: inline-block;
        margin-left: 5px;
      }
    }

    &.active {
      @include fontSemiBold;
    }
  }
}
