@import '../../../../../styles/helpers';

.nomenclature-set-default {
  display: inline-block;
  margin-right: rem(12.5px);

  .nomenclature-set-default-text {
    margin-left: rem(5px);
    font-size: rem(14px);
    vertical-align: 0;
  }
}
