@import '../../../../../styles/helpers';

.signin-form {
  width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: rem(50px);
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  h1 {
    text-align: center;
    margin-bottom: rem(25px);
  }

  .signin-form-button {
    margin-top: rem(15px);
    width: 100%;
  }
}
