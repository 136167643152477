@import '../../index.scss';

@keyframes ra-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ra-loader-count-spin {
  display: inline-block;
  border: 1px solid rgba(243, 243, 243, 0.25);
  border-top: 1px solid #ffffff;
  border-radius: 100%;
  width: rem(14px);
  height: rem(14px);
  animation: ra-spin 1s linear infinite;
  vertical-align: -2px;

  &.ra-spin-size-big {
    width: rem(20px);
    height: rem(20px);
    border: 2px solid rgba(243, 243, 243, 0.25);
    border-top: 2px solid #ffffff;
    vertical-align: -3px;
  }
}
