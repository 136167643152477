@import '../../index';

.ra-provider-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08);
  display: flex;

  @include mq($until: small-desktop) {
    display: block;
  }

  &:not(:last-child) {
    margin-bottom: rem(20px);
  }

  .ra-provider-item-img {
    padding: rem(15px 25px);
    width: 30%;
    // display: flex;
    flex-wrap: wrap;

    @include mq($until: small-desktop) {
      padding: rem(25px);
      border-bottom: 1px solid #dadada;
      display: block;
      width: 100%;
    }

    @include mq($until: tablet) {
      padding: rem(15px 25px 0);
    }

    .ra-provider-item-img-container {
      width: 100%;
      height: 175px;
      display: flex;
      text-align: center;

      img {
        object-fit: contain;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }

    .ra-provider-description-show-more {
      display: none;
      width: 100%;
      text-align: center;
      margin-top: rem(15px);

      @include mq($until: tablet) {
        display: block !important;
      }
    }

    > img {
      filter: blur(0);
      display: block;
      transition: 0.3s;
      margin: auto;
    }

    .ra-provider-expanded-list {
      margin-top: rem(12.5px);
    }
  }

  .ra-provider-item-content {
    width: 70%;
    display: flex;

    @include mq($until: small-desktop) {
      width: 100%;
    }

    @include mq($until: tablet) {
      display: block;
    }

    > div {
      &.ra-provider-item-content-1 {
        padding: rem(15px 25px 0);
        width: 100%;
        border-left: 1px solid #dadada;
        border-right: 1px solid #dadada;
      }

      &.ra-provider-item-content-2 {
        padding: rem(15px 25px);
        width: 74%;

        // display: flex;
        // flex-wrap: wrap;

        @include mq($until: tablet) {
          width: 100%;
          display: block;
        }

        .ra-provider-expanded-list {
          margin-top: rem(15px);
        }

        .ra-provider-action {
          margin: rem(27.5px auto);
          text-align: center;
          width: 100%;

          &.expanded:not(.ra-provider-hidden-button) {
            margin-bottom: rem(50px);
          }

          &.expanded.ra-provider-hidden-button {
            margin-top: 27.5px;
            margin-bottom: 27.5px;
          }

          &.ra-provider-hidden-button {
            margin-top: 65px;
          }

          @include mq($until: tablet) {
            padding: rem(25px);
            background: #f8f8f8;
            margin-top: rem(15px) !important;
            margin-bottom: rem(15px) !important;
          }

          button {
            margin-top: rem(30px);

            &.ra-provider-extern-link {
              background: #1789e6;
              border-color: #1789e6;

              &:hover {
                opacity: 0.8;
              }

              svg {
                margin-left: rem(10px);
                display: inline-block;
                font-size: rem(18px);
                top: rem(1px);
                left: inherit;

                path {
                  fill: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ra-provider-expanded-list {
  .ra-provider-expanded-item-desc {
    color: #999;
    font-size: rem(14px);
  }

  .ra-provider-expanded-item {
    margin-bottom: rem(17.5px);

    &.marked {
      > span:not(:first-child) {
        display: inline-block;
        background: #fffbd8;
      }
    }

    span {
      &:first-child {
        display: block;
        @include fontOpenSansSemiBold;
        color: #595959;
        font-size: rem(16px);
      }

      &:not(:first-child) {
        display: block;
        @include fontOpenSansRegular;
        font-size: rem(16px);
      }
    }

    span.provider-block {
      position: relative;
      @include fontOpenSansRegular;

      &:not(:last-child) {
        margin-bottom: rem(3.5px);
      }

      &.numeral {
        padding-left: rem(17.5px);

        > span {
          @include fontOpenSansRegular;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    span.ra-provider-router {
      @include fontOpenSansRegular;

      .ra-provider-router-select {
        @include fontOpenSansRegular;
        margin-bottom: rem(10px);
      }
    }
  }
}

.ra-provider-description-show-more {
  padding: rem(15px 0);
  cursor: pointer;
  transition: 0.3s;

  .show-more-text {
    @include fontSemiBold;
    font-size: rem(16px);
    color: #3487f7;
  }

  @include mq($until: tablet) {
    display: none !important;
  }

  .expanded svg {
    transform: rotate(270deg);
  }

  .not-expanded svg {
    transform: rotate(90deg);
  }

  svg {
    position: relative;
    top: 1.5px;
    fill: #3487f7;
    margin-left: 7.5px;
    transform: rotate(90deg);
    transition: 0.5s;
    font-size: 12px;
  }

  &:hover {
    color: #79b0fb;
  }
}
