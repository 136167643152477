.time {
  i {
    top: 1px;
    color: #a2bacd;
  }

  &.no-wrap {
    white-space: nowrap;
  }
}
