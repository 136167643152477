@import '../../../../../styles/helpers';

.provider-dafault-logo {
  width: rem(400px);
  height: rem(300px);
  margin-bottom: rem(25px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.provider-fields {
  .ant-form-item-children {
    display: flex;
    flex-wrap: wrap;
  }

  .provider-field {
    width: calc(50% - 4.75px);
    position: relative;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    font-size: rem(20px);
    margin-right: rem(5px);
    padding: rem(15px 15px 0);
    position: relative;

    .provider-field-delete {
      position: absolute;
      right: 15px;
      top: 15px;
    }

    &:not(:last-child) {
      margin-bottom: rem(5px);
    }

    &:last-child {
      padding-top: 0;
      text-align: center;
      width: 100%;

      .provider-field-action {
        border-top-color: transparent;
      }
    }

    .provider-field-action {
      @include fontSemiBold;
      cursor: pointer;
      transition: 0.1s;
      display: inline-block;
      line-height: 1.5;

      i {
        vertical-align: 0.5px;
        margin-left: rem(10px);
        font-size: rem(10px);
      }

      &:hover {
        color: #79e34b;
      }
    }

    b {
      display: block;
      color: #063467;
      line-height: 1.5;
      padding-right: 25px;
    }
  }
}

.provider-picker-input {
  .ant-form-item-children {
    display: flex;
  }

  .provider-picker-button {
    height: rem(36px);
    width: rem(80px);
    border-radius: 4px;
    border: 3px solid #d9d9d9;
    background: transparent;
    margin-left: rem(5px);
  }

  .provider-picker-wrapper {
    position: absolute;
    right: 0;
    z-index: 2;

    &.left {
      left: 0;
    }

    .provider-picker-mask {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.button-long-text {
  span {
    font-size: rem(18px);
    line-height: 1.5;
    margin: rem(15px 0px);
  }
}
