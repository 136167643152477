@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

// Ubuntu
@mixin fontLight {
  font-family: 'Ubuntu';
  font-weight: 300;
}

@mixin fontRegular {
  font-family: 'Ubuntu';
  font-weight: 400;
}

@mixin fontSemiBold {
  font-family: 'Ubuntu';
  font-weight: 500;
}

@mixin fontBold {
  font-family: 'Ubuntu';
  // font-weight: 700;
  font-weight: 500;
}

// Open sans font
@mixin fontOpenSansLight {
  font-family: 'Open Sans';
  font-weight: 300;
}

@mixin fontOpenSansRegular {
  font-family: 'Open Sans';
  font-weight: 400;
}

@mixin fontOpenSansSemiBold {
  font-family: 'Open Sans';
  font-weight: 600;
}

@mixin fontOpenSansBold {
  font-family: 'Open Sans';
  font-weight: 700;
}

// A mixin that converts from px to rem
$base-font-size: 11.25px !default;
$rem-baseline: $base-font-size !default;
$rem-fallback: true !default;
$rem-px-only: false !default;

@function rem-separator($list) {
  @if function-exists('list-separator') ==true {
    @return list-separator($list);
  }
  $test-list: ();
  @each $item in $list {
    $test-list: append($test-list, $item, space);
  }
  @return if($test-list==$list, space, comma);
}

@function rem-convert($to, $values...) {
  $result: ();
  $separator: rem-separator($values);
  @each $value in $values {
    @if type-of($value) == 'number' and unit($value) == 'rem' and $to== 'px' {
      $result: append($result, $value / 1rem * $rem-baseline, $separator);
    } @else if type-of($value) == 'number' and unit($value) == 'px' and $to== 'rem' {
      $result: append($result, $value / ($rem-baseline / 1rem), $separator);
    } @else if type-of($value) == 'list' {
      $result: append($result, rem-convert($to, $value...), $separator);
    } @else {
      $result: append($result, $value, $separator);
    }
  }
  @return if(length($result) ==1, nth($result, 1), $result);
}

@function rem($values...) {
  @if $rem-px-only {
    @return rem-convert(px, $values...);
  } @else {
    @return rem-convert(rem, $values...);
  }
}

@mixin rem($properties, $values...) {
  @if type-of($properties) == 'map' {
    @each $property in map-keys($properties) {
      @include rem($property, map-get($properties, $property));
    }
  } @else {
    @each $property in $properties {
      @if $rem-fallback or $rem-px-only {
        #{$property}: rem-convert(px, $values...);
      }
      @if not $rem-px-only {
        #{$property}: rem-convert(rem, $values...);
      }
    }
  }
}

// media queries mixins
$mq-static-breakpoint: desktop !default;
$mq-show-breakpoints: (mobile, small-tablet, tablet, small-desktop, desktop, wide, extra-wide) !default;
$mq-media-type: all !default;
$mq-responsive: true !default;
$mq-breakpoints: (
  mobile: 320px,
  small-tablet: 480px,
  tablet: 640px,
  small-desktop: 768px,
  desktop: 1024px,
  wide: 1280px,
  extra-wide: 1366px,
  extra-extra-wide: 1400px,
) !default;

@function mq-px2em($px) {
  @if unitless($px) {
    @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels.";
    @return mq-px2em($px * 1px);
  } @else if unit($px) == em {
    @return $px;
  }

  @return ($px / $base-font-size) * 1em;
}

@function mq-get-breakpoint-width($name, $breakpoints: $mq-breakpoints) {
  @if map-has-key($breakpoints, $name) {
    @return map-get($breakpoints, $name);
  } @else {
    @warn "Breakpoint #{$name} wasn't found in $breakpoints.";
  }
}

@mixin mq(
  $from: false,
  $until: false,
  $and: false,
  $media-type: $mq-media-type,
  $breakpoints: $mq-breakpoints,
  $responsive: $mq-responsive,
  $static-breakpoint: $mq-static-breakpoint
) {
  $min-width: 0;
  $max-width: 0;
  $media-query: ''; // From: this breakpoint (inclusive)
  @if $from {
    @if type-of($from) ==number {
      $min-width: mq-px2em($from);
    } @else {
      $min-width: mq-px2em(mq-get-breakpoint-width($from, $breakpoints));
    }
  } // Until: that breakpoint (exclusive)
  @if $until {
    @if type-of($until) ==number {
      $max-width: mq-px2em($until);
    } @else {
      $max-width: mq-px2em(mq-get-breakpoint-width($until, $breakpoints)) - 0.01em;
    }
  } // Responsive support is disabled, rasterize the output outside @media blocks
  // The browser will rely on the cascade itself.
  @if $responsive==false {
    $static-breakpoint-width: mq-get-breakpoint-width($static-breakpoint, $breakpoints);
    $target-width: mq-px2em($static-breakpoint-width); // Output only rules that start at or span our target width
    @if ($and==false and $min-width <=$target-width and ($until==false or $max-width >=$target-width)) {
      @content;
    }
  }
  // Responsive support is enabled, output rules inside @media queries
  @else {
    @if $min-width !=0 {
      $media-query: '#{$media-query} and (min-width: #{$min-width})';
    }
    @if $max-width !=0 {
      $media-query: '#{$media-query} and (max-width: #{$max-width})';
    }
    @if $and {
      $media-query: '#{$media-query} and #{$and}';
    } // Remove unnecessary media query prefix 'all and '
    @if ($media-type== 'all' and $media-query != '') {
      $media-type: '';
      $media-query: str-slice(unquote($media-query), 6);
    }
    @media #{$media-type + $media-query} {
      @content;
    }
  }
}

// skeleton loading mixins
@mixin skeleton {
  background: linear-gradient(-90deg, #dadada 0%, #ffffff 50%, #dadada 100%) !important;
  background-size: 400% 400% !important;
  animation: ra-pulse 2s ease-in-out infinite;
  border-radius: 2px;
  opacity: 0.7;

  @keyframes ra-pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

body #results-app {
  @include fontOpenSansRegular;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

#results-app {
  .skeleton,
  .skeleton-auto {
    $from: #f5f5f5;
    $to: scale-color($from, $lightness: -10%);

    height: 100%;
    width: 100%;
    @include skeleton;
  }

  .skeleton-fluid {
    @include skeleton;
  }

  .skeleton-auto {
    height: auto !important;
  }

  .skeleton-text {
    color: transparent !important;

    svg path {
      fill: transparent !important;
    }
  }

  .skeleton-img {
    filter: blur(5px) grayscale(100%) !important;
  }

  .grey {
    color: #ddd !important;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .title {
    @include fontSemiBold;
    color: #ababab;
  }

  .no-padding {
    padding: 0;
  }

  .no-margin {
    margin: 0;
  }

  .empty-text {
    color: #fff;
    font-size: rem(18px);
    text-align: center;
    padding: 0;
    margin: rem(70px 15px 25px);
  }

  .center {
    text-align: center;
  }
}
