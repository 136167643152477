@import '../../index';

.ra-seeking-form {
  &.ra-seeking-search {
    display: flex;
    margin: rem(25px 0 0);

    .ra-seeking-autocomplete-label {
      display: none;
    }

    .ra-button {
      min-width: auto;
      height: rem(60px);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .ra-button-text {
        font-size: rem(20px);
      }

      @include mq($until: small-desktop) {
        padding-left: rem(25px);
        padding-right: rem(25px);

        .ra-button-text {
          font-size: rem(16px);
        }
      }
    }

    .ra-seeking-autocomplete {
      display: block;
      width: 100%;
      height: rem(60px);

      .ra-autocomplete-input {
        height: rem(60px);

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .ra-seeking-autocomplete {
    position: relative;

    .ra-seeking-close {
      position: absolute;
      right: rem(15px);
      top: calc(50% - 1px);
      transform: translateY(-50%);
      z-index: 2;
      color: #595959;
      font-size: rem(36px);
      font-weight: 100;
      cursor: pointer;

      &:hover {
        color: #595959;
      }
    }
  }

  .ra-seeking-autocomplete-label {
    font-size: rem(20px);
    color: #fff;
    @include fontBold;
    margin-bottom: rem(5px);
  }

  .ra-seeking-error {
    color: red;
    text-align: left;
    margin-top: rem(2.5px);
  }
}

.ra-seeking-card {
  p {
    color: #fff;
    margin: rem(0 0 50px);
    font-size: rem(20px);
    @include fontRegular;

    @include mq($until: small-desktop) {
      font-size: rem(16px);
      margin: rem(0 0 25px);
    }
  }
}

.ra-seeking-alternative-title {
  cursor: not-allowed;
  background: #fafaf5 !important;
  color: #ccc !important;
}
