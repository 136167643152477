@import './helpers', './resources/Form', './resources/Divider', './resources/Skeleton', './resources/Checkbox',
  './resources/Modal', '../styles/media-queries';

html,
body {
  font-size: 10px;
}

body {
  > #root {
    min-height: 100vh;
    @include fontOpenSansRegular;
  }
}

h1,
h2,
h3,
h4 {
  line-height: initial;
}

.center {
  text-align: center;
}

.block {
  width: 100%;
}

.grey {
  color: #ddd !important;
}

.capitalize {
  text-transform: capitalize;
}

.mt-5 {
  margin-top: rem(5px) !important;
}

.mt-10 {
  margin-top: rem(10px) !important;
}

.mb-15 {
  margin-bottom: rem(15px) !important;
}

.mb-20 {
  margin-bottom: rem(20px) !important;
}

.title {
  @include fontSemiBold;
  color: #063467;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.empty-text {
  color: #ccc;
  font-size: rem(18px);
  text-align: center;
  padding: 0;
  margin: rem(70px 15px 25px);
}

.iconed-text {
  display: block;
  position: relative;
  padding-left: rem(35.5px);
  color: #2a3846 !important;

  svg,
  i {
    color: #a2bacd;
    position: absolute;
    left: 0;
    top: 1.5px;
    margin: 0 !important;
  }
}

table td {
  @include mq($until: small-desktop) {
    display: block;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
