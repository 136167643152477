@import '../../../../../styles/helpers';

.order-resend {
  @include fontSemiBold;

  &.unactive {
    color: #ccc;
    cursor: not-allowed;
  }

  &.active {
    color: #063467;
    cursor: pointer;

    &:hover {
      color: #084a96;
    }
  }
}
