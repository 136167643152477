@import '../../index.scss';

.ra-skybar-container {
  position: relative;
  height: rem(8px);
  width: 100%;

  .ra-skybar-bar {
    height: 100%;
    width: 100%;
    background: #d9d7e2;
    border-radius: 4px;
  }

  .ra-skybar-pattern {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    min-width: rem(10px);
    height: 100%;
    background: #61ab3a;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: width 0.5s;

    &.filled {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
