@import '../../index';

@keyframes ra-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ra-autocomplete-mask {
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.ra-autocomplete {
  z-index: 2;
  position: relative;

  .ra-autocomplete-suggestions-message {
    margin-top: rem(2.5px);
    background: #fffbd8;
    color: #000;
    border-bottom: 1px solid #d9d9d9;
    padding: rem(5px 20px);
    font-size: rem(16px);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ra-autocomplete-input {
    width: 100%;
    height: rem(70px);
    border: 1px solid #d9d9d9;
    background-color: #fff;
    padding: rem(5px 20px);
    border-radius: 4px;
    font-size: rem(18px);
    outline: none !important;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  .ra-autocomplete-suggestions {
    position: absolute;
    left: 0;
    top: rem(72.5px);
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.1);

    &.alternative {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      top: rem(107px);
    }

    li {
      position: relative;
      display: block;
      padding: rem(5px 20px);
      font-size: rem(16px);

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &.active,
      &:hover {
        background-color: #f6fff0;
        cursor: pointer;
        color: #464646;
      }

      .ra-loader-suggestion-spin {
        position: absolute;
        right: 15px;
        top: 8.5px;

        display: inline-block;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-top: 1px solid #464646;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        animation: ra-spin 1s linear infinite;
      }
    }
  }
}
