@import '../../../styles/helpers';
@import '../../../styles/media-queries';

.card {
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.35);
  padding: rem(60px 90px);
  margin: rem(200px auto);
  box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08);

  @include mq($until: small-desktop) {
    padding: rem(10px);
  }

  > h1,
  > h2,
  > h3,
  > .zh-card-header h1,
  > .zh-card-header h2,
  > .zh-card-header h3,
  .title {
    color: #fff;
    font-size: rem(64px);
    @include fontBold;
    margin: rem(0 0 7.5px);

    @include mq($until: small-desktop) {
      font-size: rem(42px);
    }
  }

  > h3,
  h3.title {
    font-size: rem(36px);

    @include mq($until: small-desktop) {
      font-size: rem(32px);
    }
  }

  p {
    color: #fff;
    margin: rem(0 0 50px);
    font-size: rem(20px);
    @include fontRegular;

    &.small {
      font-size: rem(14px);
      margin: rem(0 0 25px);
    }

    &.no-margin {
      margin: 0;
    }

    b,
    strong {
      color: #fff;
    }

    @include mq($until: small-desktop) {
      font-size: rem(16px);
      margin-bottom: rem(25px);

      &.small {
        margin-bottom: rem(20px);
      }
    }
  }

  &.card-wide {
    width: 100%;
    max-width: 1140px;
  }

  &.card-medium {
    margin: rem(100px auto);
    width: 100%;
    max-width: 1025px;

    @include mq($until: small-desktop) {
      margin: rem(75px auto);
    }
  }

  &.card-small {
    padding: rem(85px);
    margin: rem(165px auto);
    width: 100%;
    max-width: 660px;

    @include mq($until: small-desktop) {
      margin: rem(75px auto);
      padding: rem(50px 30px);
    }
  }

  &.results-app-step-2 {
    padding: rem(60px 90px);

    @include mq($until: small-desktop) {
      padding: rem(30px);
    }
  }

  &.vertical-fluid,
  &.results-app-step-2 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: rem(165px);
    margin-bottom: 0;
    width: 100%;
    max-width: 1025px;
    min-height: calc(100vh - 55px) !important;

    @include mq($until: small-desktop) {
      margin-top: rem(70px);
      min-height: calc(100vh - rem(70px)) !important;
    }
  }

  .card-content {
    padding: rem(75px);
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 8.4px 1.6px rgba(51, 51, 51, 0.08);

    @include mq($until: small-desktop) {
      padding: rem(25px);
    }

    &.no-padding {
      padding: 0;
    }

    &:not(:last-child) {
      margin-bottom: rem(35px);
    }

    p,
    strong {
      color: #303030;
    }

    .provider-logo {
      margin-top: rem(25px);
      max-width: rem(250px);
      max-height: rem(115px);
      float: right;

      @include mq($until: small-desktop) {
        float: none;
        width: 70%;
        height: 100%;
        margin: auto;
        max-height: 100%;
        margin-top: -2.22222rem;
      }
    }

    .order-form {
      label {
        padding: 2px 0;
        display: block;
        font-size: 16px;
      }
      .ant-input {
        height: 48px;
        margin-top: 2px;
        border-radius: 8px;
      }

      .ant-form-item-label {
        @include mq($until: small-desktop) {
          text-align: left;
        }
      }

      .ant-input-group-compact {
        input:first-child {
          border-radius: 8px 0 0 8px;
        }
        input:last-child {
          border-radius: 0 8px 8px 0;
        }
      }

      .ant-calendar-picker {
        width: 100%;

        input {
          padding-left: 45px;
        }

        .ant-calendar-picker-icon {
          right: auto;
          left: 15px;
          font-size: 18px;
        }
      }

      .ant-col {
        @include mq($until: small-desktop) {
          margin-top: 0;
        }

        .ant-col-12:first-child {
          padding-right: 12px;
          @include mq($until: small-desktop) {
            padding-right: 0;
          }
        }
        .ant-col-12:last-child {
          padding-left: 12px;
          @include mq($until: small-desktop) {
            padding-left: 0;
          }
        }
      }

      .checkbox-small {
        & > span {
          font-size: 14px;
          line-height: 1.2;
        }
      }
      .ant-form-item {
        margin-bottom: 16px;
      }
    }

    table {
      font-size: rem(29px);
      margin-top: rem(13px);
      width: 100%;
      line-height: rem(42px);

      tr {
        td {
          .label {
            font-weight: 600;
            margin-bottom: rem(15px);
          }

          div:not(:last-child) {
            margin-bottom: rem(10px);
          }

          .anticon {
            margin-left: rem(10px);

            svg {
              color: #dedede;
            }
          }
        }

        &.total-tr {
          font-weight: 700;

          td {
            padding-top: rem(27.5px);
            font-size: rem(32.5px);
            font-weight: 700;
          }
        }
      }

      &.details {
        margin-bottom: -20px;

        td {
          vertical-align: top;
          padding-bottom: 10px;
          padding-bottom: 25px;
          line-height: 1.6rem;
        }

        td:first-child {
          width: 43%;
        }
      }
    }
  }

  .zh-card-header {
    position: relative;
    display: flex;

    @include mq($until: small-desktop) {
      display: block;
    }

    .zh-card-header-content {
      width: 100%;
    }

    .zh-card-header-toolbar {
      padding: rem(7.5px 0px 7.5px 25px);
      width: auto;

      @include mq($until: small-desktop) {
        padding: 0;
      }
    }
  }
}
