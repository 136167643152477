@import '../../index';

.ra-slider-container {
  position: relative;

  .ra-slider {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: rem(15px);
    border-radius: 1px;
    background: #dadada;
    outline: none;
    cursor: pointer;

    &.ra-slider-disabled {
      cursor: not-allowed;
    }

    &::-webkit-slider-thumb {
      opacity: 0;
    }

    &::-moz-range-thumb {
      opacity: 0;
    }
  }

  .ra-slider-pattern {
    height: rem(16px);
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
    background-image: repeating-linear-gradient(45deg, #73c644, #73c644 15px, #61ab3b 15px, #61ab3b 30px);
    pointer-events: none;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: rem(25px);
      height: rem(25px);
      border-radius: 50%;
      background: #fff;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.ra-slider-pattern-first:after {
      transform: translate(0, -50%);
    }

    &.ra-slider-pattern-last:after {
      transform: translate(-100%, -50%);
    }
  }

  .ra-slider-direction {
    display: flex;
    margin-top: rem(10px);

    span {
      font-size: rem(12px);
      white-space: nowrap;
      color: #a39791;
      width: 100%;
      display: block;

      &:last-child {
        width: auto;
      }
    }
  }
}

.ra-slider-hint {
  position: relative;

  &:hover {
    .ra-slider-hint-text {
      display: block;
    }
  }

  .ra-slider-hint-text {
    width: rem(250px);
    @include fontOpenSansRegular;
    padding: rem(10px);
    font-size: rem(14px);
    background: rgba(0, 0, 0, 1);
    color: #fff;
    border-radius: 4px;
    display: none;
    position: absolute;
    right: rem(15px);
    top: rem(-80px);

    &:after {
      content: ' ';
      clear: both;
      display: block;
      position: absolute;
      bottom: rem(-5px);
      right: rem(30px);
      transform: rotate(45deg);
      padding: rem(5px);
      background: rgba(0, 0, 0, 1);
    }
  }

  svg {
    vertical-align: rem(-2px);

    path {
      color: #ccc;
    }
  }
}
